import { Injectable } from '@angular/core';
import { Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class MicroenterpriseGuard  {

  constructor(
    private auth: AuthService,
    private router: Router
  ) {}

  canLoad(route: Route,segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const obs1 = this.auth.user$;
    const obs2 = obs1.pipe(switchMap(
      res => {
        if (res == null || undefined) {
          return of({isMicroenterprise: false})
        }
        return this.auth.isMicroenterprise(res?.uid)
      }
    ));
    return obs2.pipe(switchMap(
      res => {
        if (res) {
          if (res.isMicroenterprise == true) {
            return of(true);
          } else {
            this.router.navigate(['']);
            return of(false);
          }
        } else {
          this.router.navigate(['']);
          return of(false);
        }
      }
    ))
  }
}
