<div style='padding:15px'>
  <h2>Terms of Service</h2>

  <h3><u>General</u></h3>
  <p>
    This website is owned and operated by Mercando (referred to hereafter as 'mercando.com.au,' 'We,' 'Us,', 'Our', or 'Our Website').
    These are the mercando.com.au Terms of Service and Conditions of Use (Terms). 
    'You' and 'Your' means the person using, browsing or otherwise accessing any content or data 
    on mercando.com.au, 
    including any subdomains of mercando.com.au, or related top-level domains, mobile sites, 
    Apps, APIs and widgets.
    Mercando.com.au is bound by and complies with the 
    <a href='https://www.legislation.gov.au/Series/C2004A03712' target='_blank'>Privacy Act 1988 (Cth) (Privacy Act)</a>
    and the 
    <a href='https://www.oaic.gov.au/individuals/privacy-fact-sheets/general/privacy-fact-sheet-17-australian-privacy-principles' target='_blank'>Australian Privacy Principles (APPs)</a> 
    and other relevant privacy laws.
  </p>
  <p>
    To use mercando.com.au, you must be at least 18 years old and capable of forming a binding legal contract with Us 
    and are not barred from using mercando.com.au under applicable law. If you are not at least 18 years old, 
    You must have a parent or guardian’s consent on your behalf to your use mercando.com.au. 
    You acknowledge and agree that by using, browsing or otherwise accessing mercando.com.au, 
    You agree to be bound by these terms and conditions of use (Terms), and our Privacy Policy. 
    You agree to ensure that any person who accesses Our Websites on Your behalf abides by these Terms. 
    You acknowledge that We provide use and access to Our Websites to You subject to these Terms.
    We reserve the right to change any of the Terms or other conditions for using Our Websites at any time 
    by publishing new terms or conditions on mercando.com.au. 
    Your use of Our Websites constitutes Your acceptance of those terms and conditions. 
    Should You object to any of Our terms and conditions of use or other notices on Our Websites 
    Your sole option is to immediately cease Your use of Our Websites. 
  </p>
  <h3><u>Restrictions on use of mercando.com.au</u></h3> 
  <p>
    In accessing or using mercando.com.au you agree that you will not:
    <br>
    Use any automated device, software, process or means to access, retrieve, scrape, 
    or index mercando.com.au or any content on Our Website without Our express prior written consent.<br>
    Use any device, software, process or means to interfere or attempt to interfere with the 
    proper working of Our Website.
    Undertake any action that will impose a burden or make excessive traffic demands 
    on Our infrastructure that we deem, in Our sole discretion to be unreasonable or disproportionate 
    site usage;<br>
    Use or index any content or data on Our Website for purposes of competing with Us in 
    any manner that we have not specifically authorised.<br>
    Transmit spam, chain letters, contests, junk email, surveys, or other mass messaging, 
    whether commercial in nature or not.<br>
    Use Our Website or any content from Our Website in any manner which is, in Our 
    sole discretion, not reasonable and / or not for the purpose it is made available.<br>
    Violate the rights of any person, including copyright, trade secret, privacy right, or 
    any other intellectual property or proprietary right.<br>
    Pose as any person or entity or attempt to solicit money, passwords or personal 
    information from any person.<br>
    Reproduce, republish, retransmit, modify, adapt, distribute, translate, create derivative works 
    or adaptations of, publicly display, sell, trade, or in any way exploit Our Website or any 
    content on Our Website, except as expressly authorised by us;<br> or
    Transmit or attempt to transmit any computer viruses, worms, defects, Trojan horses or 
    other items of a destructive nature.<br>
    We reserve the right to exercise whatever means we deem necessary to prevent unauthorised 
    access to or use of Our Websites, including, but not limited to, instituting technological 
    barriers, or reporting your conduct to any person or entity.
  </p>
  <h3><u>Intellectual Property</u></h3> 
  <p>
    Unless indicated otherwise, all intellectual property in mercando.com.au is owned 
    or licensed by Us. Subject to these Terms, You agree that You will not infringe Our intellectual property 
    as contained in Our Website and will not modify, copy, republish, frame, distribute or communicate 
    any part of Our Website or any information contained in it or otherwise use Our Website in a 
    way which will infringe Our intellectual property or other rights.
  </p>
  <h3><u>Use of Information</u></h3> 
  <p>
    You agree that content You provide to Us in the course of accessing or using Our Website, 
    will be available to Us to use in any manner We think fit subject to applicable provisions of any 
    legislation including, without limitation, privacy legislation.
  </p>
  <p>
    You agree, and continuously represent and warrant each time You access mercando.com.au that 
    You will not upload or otherwise provide any content which:
    <br>
    Is inaccurate, misleading, deceptive, incomplete, defamatory, fraudulent, unlawful, tortious or confidential;
    <br>
    Infringes the intellectual or other proprietary interests of third parties.
    <br>
    Contains spam, computer viruses, computer code, files or programs or other harmful 
    components that are designed to interrupt, destroy, change or limit the functionality of Our 
    Websites or any other computer software, hardware or other electronic equipment, information 
    which in any way impinges on another user's use or enjoyment of mercando.com.au or 
    otherwise breaches or encourages other users to breach these Terms.
    <br>
    Violates any law, statute or regulation; and/or
    Forges information to disguise the origin of any Content.
  </p>
  <h3><u>Third Party Content</u></h3> 
  <p>
    Our Website may contain content provided to Us by other parties (Third Party Content). We are not responsible for, 
    do not endorse and make no representations either expressly or impliedly concerning Third Party Content. 
    Third Party Content does not represent Our views. You rely on Third Party Content completely 
    at Your own risk. 
  </p>
  <h3><u>Privacy</u></h3> 
  <p>
    You agree to comply with Our privacy policy, guidelines and statements as may be applicable 
    from time to time, which are incorporated into these Terms, and can be found at Privacy Policy.
  </p>
  <h3><u>Limitation of Liability</u></h3>
  <p>
    Under no circumstances shall mercando.com.au, its officers, directors, agents, third-party providers, affiliates, 
    or licensors be liable for any direct, indirect, incidental, punitive, special, or consequential damages 
    (including without limitation, attorneys' fees), or any loss of profits or revenues or loss of data, use, 
    good-will, business opportunity, or other intangible losses, whether in an action of contract, negligence 
    or other tortious action, that result from the use of, or the inability to use, any materials available on the Site, 
    even if mercando.com.au has been advised of such damages. If you are dissatisfied with any mercando.com.au 
    information or other materials, or with any of the terms and conditions contained in the Site, 
    your sole and exclusive remedy is to discontinue using mercando.com.au. 
  <br>   
    The limitations of this subsection shall apply to any theory of liability, whether based on warranty, contract, 
    statute, tort (including negligence) or otherwise, and whether or not mercando.com.au has been informed of the 
    possibility of any such damage, and even if a remedy set forth herein is found to have failed of its essential 
    purpose. However, because some states/jurisdictions do not allow the exclusion or limitation of liability for 
    consequential or incidental damages, the above limitation may not apply to you.
  </p>
  <h3><u>Disclaimer</u></h3> 
  <p>
    Except as provided by law, mercando.com.au is provided 'as is' and without any warranty or 
    condition, express or implied.
    We do not warrant that the information contained in or accessible through mercando.com.au 
    is accurate, suitable for Your purposes or without errors, omissions or viruses. 
    We do not guarantee continuous, uninterrupted or secure access to mercando.com.au. 
    You acknowledge that access and Use of mercando.com.au (including the software 
    operating in connection with mercando.com.au) may be interfered with by numerous factors 
    outside of Our control. We do not warrant the accuracy of any advice, opinion, statement, 
    representation or other information displayed on or accessible through mercando.com.au.
    <br>
    To the maximum extent permitted by law, We make no representations or warranties in respect of the 
    information and materials available on mercando.com.au or the means of accessing that 
    information and material (including the software operating in connection with mercando.com.au). 
    We make no warranty or representation and accept no responsibility for any websites operated or 
    controlled by entities other than mercando.com.au that are or may become linked or 
    framed to or from mercando.com.au.
  </p>

  <h3><u>Membership</u></h3>
  <p>
    Mercando.com.au may offer an online membership service beyond what is currently offered to all visitors of our website. 
    By completing the registration process, paying all required membership fees, or signing up for a free account, 
    and using your membership (paid or by free), you are indicating that you agree to also be bound by all 
    of the additional terms in the following two sections of this agreement.  
  </p> 

  <h3><u>Registration, Access and Security</u></h3>
  <p>
    Only you can use your account, and no other individual may access your account using the same email.
    You will be required to give us certain registration information, all of which must be accurate and updated. 
    Failure to comply with the foregoing shall constitute a breach of this Agreement, which may result in immediate 
    termination of your account. You shall be responsible for maintaining the confidentiality of your password.
    <br>   
    You shall notify mercando.com.au, at help@mercando.com.au, of any known or suspected unauthorized use(s) of your account, 
    or any known or suspected breach of security, including loss, theft, or unauthorized disclosure of your password.
    <br>
    You are solely and fully responsible for all usage or activity on your account, 
    including use of the account by any third party authorized by you to use your user ID and password. 
    Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your account, 
    at mercando.com.au's sole discretion, and mercando.com.au may refer you to appropriate law enforcement agencies.
    <br>    
    PAYMENTS AND TERMINATION/CANCELLATION OF SERVICE. You can always find the current Membership Fees and charges 
    posted on mercando.com.au homepage. All fees are due in advance. Members can renew their account at any time 
    using our online payment system.
    <br>  
  </p>

  <h3><u>Changes to content & Availability of Service</u></h3>
  <p>
    We may make improvements and/or changes to mercando.com.au at any time in our sole discretion. 
    We may also choose to cease publication at any time in our sole discretion. 
    Periodic updates to the Site are not guaranteed and we cannot guarantee that mercando.com.au will be up 
    or fully operational at all times.
        
    We also retain the right to create limits on use and storage at our sole discretion at any time without prior notice to you.
  </p>

  <h3><u>Ending These Terms</u></h3>
  <p>
    The Terms will continue to apply until they are terminated by either you or mercando.com.au in accordance with this 
    section. You may end your legal agreement with mercando.com.au at any time for any reason by deactivating your 
    account and discontinuing your use of the Site and notifying us at help@mercando.com.au.  
    We may suspend or terminate your account or cease providing you with all or part of mercando.com.au 
    at any time for any reason, including, but not limited to, if we reasonably believe: (i) you have violated these 
    Terms; (ii) you create risk or possible legal exposure for us; or (iii) our provision of mercando.com.au to you is no 
    longer commercially viable. Nothing in this section shall affect mercando.com.au's rights to change, 
    limit or stop the provision of mercando.com.au without prior notice.
  </p>

  <h3><u>indemnification</u></h3>
  <p>
    You hereby indemnify, defend and hold harmless mercando.com.au and all officers, directors, owners, agents, 
    information third-party providers, affiliates, licensors and licensees (collectively, the 'Indemnified Parties') 
    from and against any and all liability and costs, including, without limitation, reasonable attorneys' fees, 
    incurred by the Indemnified Parties in connection with any claim arising out of any breach by You or any user of 
    Your account of this Agreement or the foregoing representations, warranties and covenants. 
    You shall cooperate as fully as reasonably required in the defense of any such claim. mercando.com.au reserves the 
    right, at its own expense, to assume the exclusive defense and control of any matter subject 
    to indemnification by you. 
  </p>

  <h3><u>Waiver</u></h3>
  <p>
    Mercando.com.au's failure to enforce any right or provision of these Terms shall not be deemed a waiver of such right 
    or provision.
  </p>

  <h3><u>Severability</u></h3>
  <p>
    If any part of these Terms of Service or Our Privacy Policy is determined to be invalid or unenforceable pursuant to applicable law including, 
    but not limited to, the warranty disclaimers and liability limitations set forth above, 
    then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that 
    most closely matches the intent of the original provision and the remainder of the Agreement shall continue in 
    effect.
  </p>

  <h3><u>Entire Agreement</u></h3>
  <p>
    This agreement, in concert with the Privacy Statement, constitutes the entire agreement between you and Mercando 
    with respect to the site mercando.com.au. It supersedes all prior or contemporaneous communications and proposals, 
    whether electronic, oral or written, between You and mercando.com.au. A printed version of this agreement and of any 
    notice given in electronic form will be admissible in judicial or administrative proceedings based upon or 
    relating to this agreement to the same extent and subject to the same conditions as other business documents and 
    records originally generated and maintained in printed form. 
    Contact us at help@mercando.com.au if you have any questions about this agreement.
  </p>
</div>
