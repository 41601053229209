import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private title: Title, private meta: Meta,) { }

  ngOnInit(): void {
    this.setMetaTags();
  }

  setMetaTags(): void {
    this.title.setTitle('Choose your gardening path');
    this.meta.updateTag({ name: 'twitter:card', content: 'summary' });
    this.meta.updateTag({ name: 'og:url', content: 'https://mercando.com.au/' });
    this.meta.updateTag({ name: 'og:title', content: 'Choose your gardening path' });
    this.meta.updateTag({ name: 'og:description', content: 'Learn how you can become a gardener with Mercando' });
    this.meta.updateTag({ name: 'og:image', content: 'https://mercando.com.au/assets/images/about-us-social.jpg' });
  }

}
