import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AngularFireAuthGuard, AuthPipe, canActivate, emailVerified, hasCustomClaim, isNotAnonymous, redirectLoggedInTo, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { CheckoutComponent } from './checkout/checkout.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { map } from 'rxjs/operators';
import { User } from './interfaces/user';
import { pipe } from 'rxjs';
import { PurchasesComponent } from './purchases/purchases.component';
import { MicroenterpriseGuard } from './services/guards/microenterprise.guard';
import { WpacsComponent } from './old-vendor-page-reroutes/wpacs/wpacs.component';
import { RegisterComponent } from './register/register.component';

const loginCheck: AuthPipe = map((user:any) => (!!user && !user.providerData[0].providerId.includes('password')) || (!!user && user.providerData[0].providerId.includes('password') && user.emailVerified));
const redirectLoginCheck = (redirect: any[]) => pipe(loginCheck, map(loggedIn => loggedIn || redirect));
const customAuthGuard = () => redirectLoginCheck(['/logged-out']);

const routes: Routes = [
  {
    path: 'terms-of-service',
    component: TermsOfServiceComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },  
  { path: '', loadChildren: () => import('./about/about.module').then(m => m.AboutModule) },
  // { path: 'community-network', loadChildren: () => import('./business-directory/business-directory.module').then(m => m.BusinessDirectoryModule) },
  { path:'checkout', component: CheckoutComponent, ...canActivate(customAuthGuard)},
  { path:'purchases', component: PurchasesComponent, ...canActivate(customAuthGuard)},

  { path: 'register', component: RegisterComponent },
  // { path: 'list-your-service', loadChildren: () => import('./list-your-business/list-your-business.module').then(m => m.ListYourBusinessModule) },
  // { path: 'ndis-program', loadChildren: () => import('./referral/referral.module').then(m => m.ReferralModule) },
  // { path: 'pricing', loadChildren: () => import('./list-your-business/list-your-business.module').then(m => m.ListYourBusinessModule) },
  // { path: 'directory-of-ndis-employment-support-providers-for-starting-a-business', loadChildren: () => import('./ndis-providers/ndis-providers.module').then(m => m.NdisProvidersModule) },
  { 
    path: 'business-admin', 
    loadChildren: () => import('./microenterprise/microenterprise.module').then(m => m.MicroenterpriseModule),
    ...canActivate(customAuthGuard),
    canLoad:[MicroenterpriseGuard]
  },
  // { 
  //   path: 'microenterprise-admin', 
  //   loadChildren: () => import('./microenterprise/microenterprise.module').then(m => m.MicroenterpriseModule),
  //   ...canActivate(customAuthGuard),
  //   canLoad:[MicroenterpriseGuard]
  // },

  // Redirect to maintain old links in advertisements:
  { path: 'contractor-admin', redirectTo: 'business-admin', pathMatch: 'full' },
  // { path: 'business-support', redirectTo: '', pathMatch: 'full' },
  // { path: 'pricing', redirectTo: 'list-your-service', pathMatch: 'full' },
  // { path: 'list-your-business', redirectTo: 'pricing', pathMatch: 'full' },
  // { path: 'community-marketplace', redirectTo: '', pathMatch: 'full' },
  // { path: 'community-network', redirectTo: '', pathMatch: 'full' },
  // { path: 'ndis-referral', redirectTo: 'ndis-program', pathMatch: 'full' },
  // { path: 'referral', redirectTo: 'ndis-program', pathMatch: 'full' },
  // { path: 'join', redirectTo: '', pathMatch: 'full' },

  // Reroutes for old vendor pages that have been transitioned to their own domains:
  // {
  //   path: 'wpacs',
  //   component: WpacsComponent
  // },

  // You have to have vendor last because it is a catch all to allow dynamic adding of seller pages:
  {
    path: 'gardener',
    children: [
      { path: ':vendor', loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsModule) },
    ]
  },

  // Wildcard route
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled',
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
