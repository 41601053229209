import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { GetStarted } from 'src/app/interfaces/get-started';
import { ContactService } from 'src/app/services/contact.service';

@Component({
  selector: 'app-dialog-join-form',
  templateUrl: './dialog-join-form.component.html',
  styleUrls: ['./dialog-join-form.component.scss']
})
export class DialogJoinFormComponent implements OnInit {
  getStartedForm: UntypedFormGroup | any;
  loadingGetStartedCall: boolean | any;
  colorExp: string | any;
  getStartedFormResponse: string | any;

  constructor(private fb: UntypedFormBuilder, private contactService: ContactService) { }

  ngOnInit(): void {
    this.getStartedForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      number: ['', [Validators.pattern('^[0-9]*$'), Validators.minLength(8), Validators.required]],
      ndisPlanType: ['Unsure or No Plan', [Validators.required]],
      message: ['']
    });
  }

  submitGetStartedForm(getStartedFormValue: GetStarted): void {
    this.loadingGetStartedCall = true;
    this.contactService.submitGetStarted(getStartedFormValue)
    .pipe(
      finalize(() => {
        this.loadingGetStartedCall = false;
      })
    )
    .subscribe(
      res => {
        this.colorExp = '#1bbc9b';
        this.getStartedFormResponse = `Thanks, we received your form and will be in touch soon.`;
      },
      error => {
        this.colorExp = 'red';
        this.getStartedFormResponse = `Sorry, there was an error. Please refresh the page and try again.`;
      }
    );
  }
}
