import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'easyDateWithWeekday'
})
export class EasyDateWithWeekdayPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let updatedValue = '20' + value.toString();
    let year = Number(updatedValue.substring(0,4));
    let month = Number(updatedValue.substring(4,6));
    let day = Number(updatedValue.substring(6,8));
    let date = new Date(year, month-1, day).toLocaleDateString('en-US', {weekday:'short', day:'numeric', month:'short', year:'numeric'});
    return date;
  }

}
