import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PaymentIntent } from '@stripe/stripe-js';
import { BehaviorSubject, throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { Cart, CartProducts } from '../interfaces/cart';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  // paymentIntentUrl = 'http://127.0.0.1:8080/payment-intent';
  // batchRemoveBookingsUrl = 'http://127.0.0.1:8080/batch-remove-bookings';
  // returnCartUrl = 'http://127.0.0.1:8080/return-cart';
  paymentIntentUrl = 'https://mercando-api.ts.r.appspot.com/payment-intent';
  batchRemoveBookingsUrl = 'https://mercando-api.ts.r.appspot.com/batch-remove-bookings';
  returnCartUrl    = 'https://mercando-api.ts.r.appspot.com/return-cart';

  public isBrowser = isPlatformBrowser(this.platformId);

  cartItems = new BehaviorSubject([]);
  cart$ = this.cartItems.asObservable();

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any, 
    public authService: AuthService
    ) {
    if(this.isBrowser){
      this.cartItems.next(this.getCartData());
    }
  }

  getCartData() {
    if (localStorage.getItem('cart') === null) {
      localStorage.setItem('cart',JSON.stringify([]))
    }
    let localCart = JSON.parse(localStorage.getItem('cart')!);
    return localCart
  }
  addItemToCart(cartItem:any) {
    let cartArray = this.getCartData();
    cartArray.push(cartItem);
    localStorage.setItem('cart',JSON.stringify(cartArray));
    this.cartItems.next(cartArray);
  }
  clearItemFromCart(itemType:string, itemId:string, bookingDate?:number, bookingTime?:number) {
    let cartArray = this.getCartData();
    let updatedCartArray: any;
    cartArray = cartArray.forEach((element:Cart,index:number) => {   
      if (itemType === 'service') {
        if (element.bookingDate === bookingDate && element.bookingTime === bookingTime) {
          cartArray.splice(index,1);
          updatedCartArray = cartArray;
        }
        } else if (itemType === 'product') {
          if (element.itemId === itemId) {
            cartArray.splice(index,1);
            updatedCartArray = cartArray;
          }
      }
    })
    localStorage.setItem('cart',JSON.stringify(updatedCartArray));
    this.cartItems.next(updatedCartArray);
  }
  clearAllCart() {
    this.cartItems.next([]);
    localStorage.removeItem('cart');
  }

  createPaymentIntent(selectedTip:number, userDetails:object): Observable <any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

    let cart = this.getCartData();

    return this.http
                    .post<PaymentIntent>(this.paymentIntentUrl, {cartItems: cart, tip: selectedTip, user: userDetails}, options)
                    .pipe(
                      catchError(this.errorHandler));
  }

  // API does not throw error for certain payment errors as payment intent confirmation happens in browser. 
  // This removes bookings but local cart remains.
  batchRemoveBookings(): Observable <any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

    let cart = this.getCartData();

    return this.http
                    .post<PaymentIntent>(this.batchRemoveBookingsUrl, {cartItems: cart}, options)
                    .pipe(catchError(this.errorHandler));
  }

  returnCart(cartDetails: any): Observable <any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http
                    .post<PaymentIntent>(this.returnCartUrl, cartDetails, options)
                    .pipe(
                      map((cartResponse: any) => {
                        let vendorItemsList:any = [];
                        let v = cartResponse.vendor_items;
                        v.forEach((cartResponseItem:any) => {
                          vendorItemsList.push({
                            title: cartResponseItem.title,
                            imageUrl: cartResponseItem.img,
                            vendorId: cartResponseItem.vendor_id,
                            orderAmount: cartResponseItem.order_amount,
                            itemType: cartResponseItem.item_type,
                            itemId: cartResponseItem.item_id,
                            price: cartResponseItem.price,
                            // service:
                            // serviceId: cartResponseItem?.service_id,
                            bookingDate: cartResponseItem?.booking_date,
                            bookingTime: cartResponseItem?.booking_time,
                            bookingGap: cartResponseItem?.booking_gap,
                            advancedService: cartResponseItem?.advanced_service,
                            advancedServiceType: cartResponseItem?.advanced_service_type,
                            // product:
                            stockLeft: cartResponseItem?.stock_left,
                            // productId: cartResponseItem?.product_id,
                          })
                        })
                        return {
                          cartTotal: cartResponse.cart_total,
                          vendorItems: vendorItemsList
                        }
                      }),
                      catchError(this.errorHandler)
                      );
  }

  errorHandler(error: HttpErrorResponse): any {
    console.log(error);
    return throwError(error || 'Server Error');
  }
}