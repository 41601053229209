import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from "@angular/forms";

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions, } from '@stripe/stripe-js';
import { PaymentsService } from '../services/payments.service';
import { finalize, switchMap } from 'rxjs/operators';
import { Item } from '../interfaces/vendor';
import { Cart } from '../interfaces/cart';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  // private regex: RegExp = new RegExp(/^-?[0-9]+(\.[0-9]*){0,1}$/g);
  @ViewChild(StripeCardComponent) card!: StripeCardComponent;
  loading = true;
  // displayedColumns: string[] = ['Item', 'Price', 'Qty', 'Subtotal'];
  displayedColumns: string[] = ['title', 'price', 'qty', 'subTotal', 'delete'];
  checkoutItems!: Array<Cart>;
  stripeForm!: UntypedFormGroup;
  tipForm!:UntypedFormGroup;
  contactForm!:UntypedFormGroup;
  // tipSubmitted: boolean | undefined;
  tipSubmitted = false;
  sliderValue = 15;
  customTip = false;
  cartTotal!: number;
  // tipAmount = 0;
  // totalAmount = 15;
  totalPaymentDue: number | undefined;
  subscription: any;
  isSubscription = false;
  cardComplete = false;
  paymentLoading = false;
  paymentSuccess: string | undefined;
  paymentFailure: string | undefined;

  cardOptions: StripeCardElementOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#212121',
        color: '#212121',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#212121'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
  };

  constructor(
    private fb: UntypedFormBuilder, 
    private stripeService: StripeService, 
    public paymentsService: PaymentsService,
    public authService: AuthService,
    private router: Router,
    ) {}

  ngOnInit(): void {
    this.getVendorItems();

    this.tipForm = this.fb.group({
      tipAmount: [0, [Validators.required, Validators.pattern(/\d+/)]],
      customTipInput: [0],
      sliderValue: [15]
    });
    this.myInput.valueChanges 
      .subscribe(() => {
          this.myInput.setValue(Math.abs(this.myInput.value), {emitEvent: false});
    });

    this.getAvailableUserData();

    this.stripeForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      number: ['', [Validators.required,Validators.pattern('[- +()0-9]{8,}')]],
      email: ['', [Validators.required,Validators.email]],
      address: ['', [Validators.required]],
      uid: ['', [Validators.required]],
      // subscription: ['no'],
      // subscriptionPeriod: ['weekly'],
    });
  }

  getVendorItems(): any {
    let cartItems = {'cartItems':this.paymentsService.getCartData()};
    this.loading = true;
    this.paymentsService.returnCart(cartItems)
      .pipe(
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((res:any)=> {
        this.checkoutItems = res.vendorItems;
        this.cartTotal = res.cartTotal;
        this.calculateTip();
      })
  }

  getAvailableUserData(): void {
    this.authService.user$.subscribe(
      (res) => {
        if(res == null || undefined){
          this.router.navigate([''])
        } else {
          this.stripeForm.get('uid')!.setValue(res.uid)
          if(res.firstName != null){
            this.stripeForm.get('firstName')!.setValue(res.firstName);
          }
          if(res.lastName != null){
            this.stripeForm.get('lastName')!.setValue(res.lastName);
          }
          if(res.number != null){
            this.stripeForm.get('number')!.setValue(res.number);
          }
          if(res.email != null){
            this.stripeForm.get('email')!.setValue(res.email);
          }
          if(res.address != null){
            this.stripeForm.get('address')!.setValue(res.address);
          }
        }
      }
    )
  }

  pay(): void {
    this.paymentLoading = true;
  
    this.authService.updateUserData(
      this.stripeForm.get('uid')!.value,
      {
        firstName: this.stripeForm.get('firstName')!.value,
        lastName: this.stripeForm.get('lastName')!.value,
        number: (this.stripeForm.get('number')!.value).replace(/\s/g,''),
        email: this.stripeForm.get('email')!.value,
        address: this.stripeForm.get('address')!.value,
      })

    this.paymentsService.createPaymentIntent(this.tipForm.get('tipAmount')!.value, this.stripeForm.getRawValue())
      .pipe(
        switchMap((pi:any) =>
          this.stripeService.confirmCardPayment(pi.client_secret, {
            payment_method: {
              card: this.card.element,
              billing_details: {
                name: this.stripeForm.get('firstName')!.value + ' ' + this.stripeForm.get('lastName')!.value,
              },
            },
          })
        ),
        finalize(() => {
          this.paymentLoading = false;
        })
      )
      .subscribe( 
        (result:any) => {
          // This follows the stripe ngx package documentation. It handles browser payment errors only. 
          // All other API errors are handled normally below this.
          if (result.error) {
            // Batch remove bookings from database:
            this.paymentsService.batchRemoveBookings().subscribe(res => {});

            // Show error to your customer (e.g., insufficient funds)
            console.log('subscription failed');
            console.log(result.error.message);
            this.paymentFailure = 'Sorry there was the following error: ' + result.error.message;
          } else {
            // The payment has been processed!
            if (result.paymentIntent!.status === 'succeeded') {
              // Show a success message to your customer
              this.paymentSuccess = 'Your payment was successful. Thanks for supporting Microenterprises on Mercando.'
              // Update product quantities:


              this.paymentsService.clearAllCart();
            }
          }
        },
        // This catches other API errors:
        error => {
          this.paymentFailure = 'Sorry there was the following error: ' + error.status + ' ' + error.statusText;
        }
      );
  }

  formatLabel(value:number) {
    return value + '%'
  }

  get myInput(): AbstractControl {
    return this.tipForm.controls['customTipInput'];
  }

  calculateTip() {
    let unRoundedTip = ((this.tipForm.get('sliderValue')!.value/100) * this.cartTotal);
    this.tipForm.get('tipAmount')!.setValue(Math.round((unRoundedTip + Number.EPSILON) * 100) / 100);
    this.totalPaymentDue = this.tipForm.get('tipAmount')!.value + this.cartTotal;
  }

  continuePastTip() {
    if(this.customTip === true) { 
      this.totalPaymentDue = this.tipForm.get('tipAmount')!.value + this.cartTotal;
      // this.tipForm.get('tipAmount')!.setValue(this.tipForm.get('customTipInput')!.value)
    } else {
      this.totalPaymentDue = this.tipForm.get('tipAmount')!.value + this.cartTotal;
    }
    this.tipSubmitted = !this.tipSubmitted;
  }

  toggleSubscription() {
    this.isSubscription = !this.isSubscription;
  }

  cardChange(changeEvent:any) {
    if (changeEvent.complete === true) {
      this.cardComplete = true;
    } else {
      this.cardComplete = false;
    }
  }

}
