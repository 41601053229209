// import { Pipe, PipeTransform } from '@angular/core';

// @Pipe({
//   name: 'easyQty'
// })
// export class EasyQtyPipe implements PipeTransform {

//   transform(value: number, ...args: unknown[]): unknown {
//     let updatedValue = null;
//     if (value === 100){
//       updatedValue = value.toString()[0] + ' hour ';
//     } else {
//       updatedValue = value.toString()[0] + ' hours ';
//     }
    
//     return updatedValue;
//   }

// }

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'easyQty'
})
export class EasyQtyPipe implements PipeTransform {

  transform(value: number): string {
    const hours = Math.floor(value / 100);
    const minutes = Math.round((value % 100) * 0.6);

    let hourString = hours === 1 ? `${hours} hr` : `${hours} hrs`;
    let minuteString = minutes > 0 ? `${minutes} mins` : '';

    // Adding 'and' if both hours and minutes are present
    let connector = hours > 0 && minutes > 0 ? ' and ' : '';

    return `${hourString}${connector}${minuteString}`.trim();
  }

}
