import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Cart } from '../interfaces/cart';
import { BookingData, Item, Offering, Offerings, SettingsData } from '../interfaces/vendor';
import firebase from 'firebase/compat/app'

@Injectable({
  providedIn: 'root'
})
export class MicroenterpriseAdminService {
  // addCustomCalendarEventUrl            = 'http://127.0.0.1:8080/add-custom-calendar-event';
  // updateCustomCalendarEventUrl         = 'http://127.0.0.1:8080/update-custom-calendar-event';
  // completeCalendarEventUrl             = 'http://127.0.0.1:8080/complete-calendar-event';
  // completeDisabilitySupportCalendarEventUrl = 'http://127.0.0.1:8080/complete-disability-support-calendar-event';
  // cancelCalendarEventUrl               = 'http://127.0.0.1:8080/cancel-calendar-event';
  // cancelCustomCalendarEventUrl         = 'http://127.0.0.1:8080/cancel-custom-calendar-event';
  // addCustomFreeEventUrl                = 'http://127.0.0.1:8080/add-create-free-custom-event';
  // updateCalendarEventUrl               = 'http://127.0.0.1:8080/update-calendar-event';
  // updateDisabilitySupportCalendarEventUrl = 'http://127.0.0.1:8080/update-disability-support-calendar-event';
  // rebookCalendarEventUrl               = 'http://127.0.0.1:8080/rebook-calendar-event';
  // rebookDisabilitySupportCalendarEventUrl = 'http://127.0.0.1:8080/rebook-disability-support-calendar-event';
  
  addCustomCalendarEventUrl                  = 'https://mercando-api.ts.r.appspot.com/add-custom-calendar-event';
  updateCustomCalendarEventUrl               = 'https://mercando-api.ts.r.appspot.com/update-custom-calendar-event';
  completeCalendarEventUrl                   = 'https://mercando-api.ts.r.appspot.com/complete-calendar-event';
  completeDisabilitySupportCalendarEventUrl  = 'https://mercando-api.ts.r.appspot.com/complete-disability-support-calendar-event';
  cancelCalendarEventUrl                     = 'https://mercando-api.ts.r.appspot.com/cancel-calendar-event';
  cancelCustomCalendarEventUrl               = 'https://mercando-api.ts.r.appspot.com/cancel-custom-calendar-event';
  addCustomFreeEventUrl                      = 'https://mercando-api.ts.r.appspot.com/add-create-free-custom-event';
  updateCalendarEventUrl                     = 'https://mercando-api.ts.r.appspot.com/update-calendar-event';
  updateDisabilitySupportCalendarEventUrl    = 'https://mercando-api.ts.r.appspot.com/update-disability-support-calendar-event';
  rebookCalendarEventUrl                     = 'https://mercando-api.ts.r.appspot.com/rebook-calendar-event';
  rebookDisabilitySupportCalendarEventUrl    = 'https://mercando-api.ts.r.appspot.com/rebook-disability-support-calendar-event';

  showFooter = new BehaviorSubject(true);
  showFooter$ = this.showFooter.asObservable();

  vendorId = new BehaviorSubject('');
  vendorId$ = this.vendorId.asObservable();

  canInvoice = new BehaviorSubject(false);
  canInvoice$ = this.canInvoice.asObservable();

  constructor(private afs: AngularFirestore, private storage: AngularFireStorage, private snackBar: MatSnackBar, private http: HttpClient,) { }

  // This removes the footer. It's initial use case was because the mat-sidenav is fixed over it in the microenterprise-admin:
  updateFooter(showFooter:boolean) {
    this.showFooter.next(showFooter);
  }

  updateVendorId(vendorId:string) {
    this.vendorId.next(vendorId);
  }

  updateCanInvoice(canInvoice:boolean) {
    this.canInvoice.next(canInvoice);
  }

  // This updates the entire array for all sellers settings in microenterprise-admin.
  // It was not possible to update a single value in an array in firestore:
  updateVendorSettings(settingsData:SettingsData, updatedData:Offering & Item) {
    console.log('here: ',settingsData,'and: ', updatedData)
    // Update service settings:
    if (updatedData.itemType === 'service') {
      const updatedDataIndex  = settingsData.settings.offerings.findIndex(elem => elem.itemId === updatedData!.itemId);
      let updatedSettingsData = settingsData.settings;

      // Keep advanced service field if required:
      if (updatedData.advancedService === true){
        updatedData.advancedServiceType      = updatedSettingsData.offerings[updatedDataIndex].advancedServiceType;
        updatedData.advancedServiceData = updatedSettingsData.offerings[updatedDataIndex].advancedServiceData;
      }

      updatedSettingsData.offerings[updatedDataIndex] = updatedData;
  
      // Convert data back to correct database type:
      const revertedData = this.dataReModifier(updatedSettingsData);
      
      const settingsDoc: AngularFirestoreDocument<any> = this.afs.doc<SettingsData>(`sellers/${settingsData.vendorId}`);
      settingsDoc.set(
        {
          settings: revertedData
        }, 
        { merge: true }
        )
      return this.snackBar.open('Successfully Saved Service Settings', undefined, {duration: 3500});
    }
    // Update product settings:
    else if (updatedData.itemType === 'product') {
      const updatedDataIndex  = settingsData.settings.offerings.findIndex(elem => elem.itemType === 'productGroup');
      let updatedSettingsData = settingsData.settings;
      const productIndex = updatedSettingsData.offerings[updatedDataIndex].productList!.findIndex((elem:Item) => elem.itemId === updatedData!.itemId);
      
      // Keep advanced product field if required:
      if (updatedData.advancedProduct === true){
        updatedData.advancedProductType = updatedSettingsData.offerings[updatedDataIndex].productList![productIndex].advancedProductType;
      }

      // Create variable for stock left to store separately in firestore and remove it from updatedData:
      const stockLeft = updatedData.stockLeft;
      delete updatedData.stockLeft;

      updatedSettingsData.offerings[updatedDataIndex].productList![productIndex] = updatedData;

      const settingsDoc: AngularFirestoreDocument<any> = this.afs.doc<SettingsData>(`sellers/${settingsData.vendorId}`);
      settingsDoc.set(
        {
          productStockLeft:{[updatedData.itemId]:stockLeft},
          settings: updatedSettingsData
        }, 
        { merge: true }
        )
      return this.snackBar.open('Successfully Saved Product Settings', undefined, {duration: 3500});
    }
    else {
      return this.snackBar.open('Error, No Item Type', undefined, {duration: 3500});
    }
  }

  deleteProduct(settingsData:SettingsData,itemId:string,imageUrl:string) {
    const updatedDataIndex  = settingsData.settings.offerings.findIndex(elem => elem.itemType === 'productGroup');
    let updatedSettingsData = settingsData.settings;
    const productIndex      = updatedSettingsData.offerings[updatedDataIndex].productList!.findIndex((elem:Item) => elem.itemId === itemId);
    updatedSettingsData.offerings[updatedDataIndex].productList?.splice(productIndex,1);

    const settingsDoc: AngularFirestoreDocument<any> = this.afs.doc<SettingsData>(`sellers/${settingsData.vendorId}`);
    settingsDoc.set(
      {
        productStockLeft:{[itemId]:firebase.firestore.FieldValue.delete()},
        settings: updatedSettingsData
      }, 
      { merge: true }
      )
    this.storage.refFromURL(imageUrl).delete();
    return this.snackBar.open('Product Successfully Deleted', undefined, {duration: 3500});
  }

  addProduct(settingsData:SettingsData, newProductData:Item) {
    const updatedDataIndex  = settingsData.settings.offerings.findIndex(elem => elem.itemType === 'productGroup');
    let updatedSettingsData = settingsData.settings;
    const productIndex      = updatedSettingsData.offerings[updatedDataIndex].productList!.findIndex((elem:Item) => elem.itemId === newProductData.itemId);
    
    // Create variable for stock left to store separately in firestore and remove it from newProductData:
    const stockLeft = newProductData.stockLeft;
    delete newProductData.stockLeft;

    updatedSettingsData.offerings[updatedDataIndex].productList![productIndex] = newProductData;
    const settingsDoc: AngularFirestoreDocument<any> = this.afs.doc<SettingsData>(`sellers/${settingsData.vendorId}`);
    settingsDoc.set(
      {
        productStockLeft:{[newProductData.itemId]:stockLeft},
        settings: updatedSettingsData
      }, 
      { merge: true }
    )
    return this.snackBar.open('Successfully Added Product', undefined, {duration: 3500});
  }

  // Function returns data types back to the requirements for firestore database.
  // Currently only needed to do service offering:
  dataReModifier(dataToBeReverted:Offerings) {
    dataToBeReverted['offerings'].forEach((item:Offering) => {
      if (item.itemType === 'service') {
        delete item['hideAvailability'];
        item['daysOff']                 = item['daysOff']?.map(Number);
        item['minimumBookingTime']      = Number(item['minimumBookingTime']);
        item['maximumBookingTime']      = Number(item['maximumBookingTime']);
        item['bookingGap']              = Number(item['bookingGap']);
        item['noticePeriodDays']        = Number(item['noticePeriodDays']);
        item['futureBookingMonthRange'] = Number(item['futureBookingMonthRange']);
        item['availability']!['0']       = item['availability']!['0']?.map(Number);
        item['availability']!['1']       = item['availability']!['1']?.map(Number);
        item['availability']!['2']       = item['availability']!['2']?.map(Number);
        item['availability']!['3']       = item['availability']!['3']?.map(Number);
        item['availability']!['4']       = item['availability']!['4']?.map(Number);
        item['availability']!['5']       = item['availability']!['5']?.map(Number);
        item['availability']!['6']       = item['availability']!['6']?.map(Number);
      }
    })
    return dataToBeReverted
  }

  upLoadFile(event:FileList, vendorId:string, itemId:string, uid:any) {
    const file = event.item(0);
    if (file?.type.split('/')[0] !== 'image') {
      this.snackBar.open('File Upload Error!', undefined, {duration: 3500});
      throw new Error('File Upload Error')
    }
    const path = `sellers/${vendorId}/${itemId}`;
    let metadata = { customMetadata: {[uid]: 'admin'} };
    return this.storage.upload(path, file, metadata);
  }

  addCustomCalendarEvent(eventData:BookingData, vendorId:string, dateNumber:string, startTime:string) {
    // Booking event type:
    if (eventData.newEventType == 'booking') {
      const calendarEventDoc: AngularFirestoreDocument<any> = this.afs.doc<SettingsData>(`sellers/${vendorId}/bookings/${dateNumber}`);
      calendarEventDoc.get().pipe(switchMap((currentEventData:any) => {
  
        // If no firestore document exists then immediately create one:
        if (!currentEventData.exists) {
          const extraEventData   = {vendorId:vendorId,dateNumber:dateNumber,startTime:startTime};
          const updatedEventData = {...eventData, ...extraEventData};
  
          const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
  
          return this.http
            .post<BookingData>(this.addCustomCalendarEventUrl, updatedEventData, options)
            .pipe(catchError(this.errorHandler));        
        }
  
        // Check to make sure there are no existing bookings at this time:
        const numericalStartTime = Number(startTime);
        const numericalEndTime = numericalStartTime + eventData.bookingLength!;
        let isAvailable = true;
        Object.entries(currentEventData.data()).forEach((currentEvent:any) => {
          // Not using any booking gap in the manual calculation of booking availability but can't set exactly before or after existing event:
          const numericalCurrentEventStartTime = Number(currentEvent[0]);
          const numericalCurrentEventEndTime = numericalCurrentEventStartTime + currentEvent[1]['bookingLength'];
  
          if (numericalStartTime >= numericalCurrentEventStartTime && numericalStartTime <= numericalCurrentEventEndTime) {
            isAvailable = false;
          }
          else if (numericalEndTime >= numericalCurrentEventStartTime && numericalEndTime <= numericalCurrentEventEndTime) {
            isAvailable = false;
          }
        })
        
        if (!isAvailable) {
          this.snackBar.open('Error, Booking already exists on this day!', undefined, {duration: 3500});
          return of('Is not available.')
        } else {
          const extraEventData   = {vendorId:vendorId,dateNumber:dateNumber,startTime:startTime};
          const updatedEventData = {...eventData, ...extraEventData};
  
          const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
          return this.http
            .post<BookingData>(this.addCustomCalendarEventUrl, updatedEventData, options)
            .pipe(catchError(this.errorHandler));
        }
  
      })).subscribe(
        res => this.snackBar.open(`Booking successfully added.`, undefined, {duration: 5000}), 
        err => this.snackBar.open(`Error: ${err.message}. Please try again or contact support if problem persists.`, undefined, {duration: 5000})
      )
    }

    // Holiday event type:
    else if (eventData.newEventType == 'holiday') {
     
      const extraEventData   = {vendorId:vendorId,dateNumber:dateNumber,startTime:startTime};
      const updatedEventData = {...eventData, ...extraEventData};

      const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
      return this.http
        .post<BookingData>(this.addCustomCalendarEventUrl, updatedEventData, options)
        .pipe(catchError(this.errorHandler))
        .subscribe(
          res => this.snackBar.open(`Holiday successfully added.`, undefined, {duration: 5000}), 
          err => this.snackBar.open(`Error: ${err.message}. Please try again or contact support if problem persists.`, undefined, {duration: 5000})
        );
    }      

    return
  }

  updateCustomCalendarEvent(apiVersion:string, eventData:BookingData, vendorId:string, dateNumber:string, startTime:string, eventsCompletedCheck:boolean) {
    const extraEventData   = {apiVersion:apiVersion,vendorId:vendorId,dateNumber:dateNumber,startTime:startTime,eventsCompletedCheck:eventsCompletedCheck};
    let updatedCustomCalendarData = {...eventData, ...extraEventData};
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<BookingData>(this.updateCustomCalendarEventUrl, updatedCustomCalendarData, options)
      .pipe(catchError(this.errorHandler));
  }

  completeBooking(vendorId:string, bookingData:BookingData, dateNumber:string, startTime:string) {
    const updatedBookingData = {...{vendorId: vendorId,dateNumber:dateNumber, startTime:startTime}, ...bookingData}

    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<BookingData>(this.completeCalendarEventUrl, updatedBookingData, options)
      .pipe(catchError(this.errorHandler))
      // .subscribe(
      //   res => this.snackBar.open(`Event successfully marked as completed.`, undefined, {duration: 5000}), 
      //   err => this.snackBar.open(`Error: ${err.message}. Please try again or contact support if problem persists.`, undefined, {duration: 5000})
      // );
  }

  completeDisabilitySupportBooking(vendorId:string, bookingData:BookingData, dateNumber:string, startTime:string, shiftData:any) {
    const updatedBookingData = {...{vendorId: vendorId,dateNumber:dateNumber, startTime:startTime, shiftData:shiftData}, ...bookingData}

    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<BookingData>(this.completeDisabilitySupportCalendarEventUrl, updatedBookingData, options)
      .pipe(catchError(this.errorHandler))
      // .subscribe(
      //   res => this.snackBar.open(`Event successfully marked as completed.`, undefined, {duration: 5000}), 
      //   err => this.snackBar.open(`Error: ${err.message}. Please try again or contact support if problem persists.`, undefined, {duration: 5000})
      // );
  }

  cancelBooking(eventCancellationNotes:string, vendorId:string, bookingData:BookingData, dateNumber:string, startTime:string) {
    const updatedBookingData = {
                                ...{eventCancellationNotes:eventCancellationNotes,vendorId:vendorId,dateNumber:dateNumber, startTime:startTime}, 
                                ...bookingData
                              }

    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<BookingData>(this.cancelCalendarEventUrl, updatedBookingData, options)
      .pipe(catchError(this.errorHandler))
      .subscribe(
        res => this.snackBar.open(`Successfully cancelled booking.`, undefined, {duration: 5000}), 
        err => this.snackBar.open(`Error: ${err.message}. Please refresh page and try again. Contact support if problem persists.`, undefined, {duration: 5000})
      );
  }

  cancelCustomBooking(vendorId:string, bookingData:BookingData, dateNumber:string, startTime:string) {
    const updatedBookingData = {
                                ...{vendorId:vendorId,dateNumber:dateNumber, startTime:startTime}, 
                                ...bookingData
                              }
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<BookingData>(this.cancelCustomCalendarEventUrl, updatedBookingData, options)
      .pipe(catchError(this.errorHandler))
      .subscribe(
        res => this.snackBar.open(`Successfully cancelled booking.`, undefined, {duration: 5000}), 
        err => this.snackBar.open(`Error: ${err.message}. Please refresh page and try again. Contact support if problem persists.`, undefined, {duration: 5000})
      );
  }

  addFreeCustomBooking(bookingData: object, vendorId:string, dateNumber:string, startTime:string) {
    const updatedBookingData = {
                                ...{vendorId:vendorId,dateNumber:dateNumber, startTime:startTime}, 
                                ...{bookingData:bookingData}
                              }
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    console.log('here is the data:',updatedBookingData);
    return this.http
      .post<BookingData>(this.addCustomFreeEventUrl, updatedBookingData, options)
      .pipe(catchError(this.errorHandler))
  }

  updateBooking(updatedBookingData: any, vendorId:string) {
    const bookingData = {
                                ...{vendorId:vendorId}, 
                                ...{bookingData:updatedBookingData}
                              }
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<any>(this.updateCalendarEventUrl, bookingData, options)
      .pipe(catchError(this.errorHandler));
  }

  updateDisabilitySupportBooking(updatedBookingData: any, vendorId:string) {
    const bookingData = {
                                ...{vendorId:vendorId}, 
                                ...{bookingData:updatedBookingData}
                              }
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<any>(this.updateDisabilitySupportCalendarEventUrl, bookingData, options)
      .pipe(catchError(this.errorHandler));
  }

  rebookBooking(rebookBookingData: any, vendorId:string) {
    const bookingData = {
                                ...{vendorId:vendorId}, 
                                ...{bookingData:rebookBookingData}
                              }

    console.log(bookingData)
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<any>(this.rebookCalendarEventUrl, bookingData, options)
      .pipe(catchError(this.errorHandler));
  }

  rebookDisabilitySupportBooking(rebookBookingData: any, vendorId:string) {
    const bookingData = {
                                ...{vendorId:vendorId}, 
                                ...{bookingData:rebookBookingData}
                              }

    console.log(bookingData)
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};
    return this.http
      .post<any>(this.rebookDisabilitySupportCalendarEventUrl, bookingData, options)
      .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error: HttpErrorResponse) {
    console.log(error);
    return throwError(error || 'FireStorage Error');
  }
}
