<footer class='home-footer' *ngIf='microenterpriseAdminService.showFooter$ | async'>
  <section class='home-footer-top'>
    <div class='container'>
      <div fxLayout='row' fxLayout.lt-md='column' fxLayoutWrap='nowrap'>
        <div fxFlex='50' class='footer-box'>
          <h4 class='footer-box-title'><b>About Mercando</b></h4>
          <p>
            <!-- Mercando is a Melbourne based startup that operates Australia wide.  -->
            <!-- Mercando is a platform that connects you with independent and socially conscious gardeners in your local community. -->
            Mercando is a platform that connects you with ethically conscious gardeners in your local community.
          </p>
          <div>
            <br>
          </div>

          <h4 class='footer-box-title'><b>Follow Us Here</b></h4>
          <div class="socials pt-1">
            <ul>
              <li><a href='https://www.linkedin.com/company/mercando/' target='_blank' class='social-icon linkedin'></a></li>
              <li><a href='https://www.facebook.com/mercando.community' target='_blank' class='social-icon facebook'></a></li>
            </ul>
          </div>

        </div>
        <div fxFlex='25' class='footer-box'>
          <h4 class='footer-box-title'><b>Contact Mercando</b></h4>
          <mat-list>
            <!-- <mat-list-item>
              <mat-icon mat-list-icon color='primary'>phone</mat-icon>
              <h5 matLine><b>Phone</b></h5>
              <p matLine> 03 9410 9013 </p>
            </mat-list-item> -->
            <mat-list-item>
              <mat-icon mat-list-icon color='primary'>email</mat-icon>
              <h5 matLine><b>Email</b></h5>
              <p matLine> info@mercando.com.au </p>
            </mat-list-item>
            <mat-list-item>
                <mat-icon mat-list-icon color='primary'>location_on</mat-icon>
                <h5 matLine><b>Address</b></h5>
                <p matLine>Suite 10</p>
                <p matLine>Level 4 80 Market St</p>
                <p matLine>South Melbourne</p>
                <p matLine>VIC 3205</p>
              </mat-list-item>
          </mat-list>
        </div>
        <div fxFlex='25' class='footer-box'>
          <h4 class='footer-box-title'><b>Privacy Policy & Terms of Service</b></h4>
          <p>Please read and agree to our 
            <a class='policy-links' routerLink='/terms-of-service'>Terms of Service</a> 
            &
            <a class='policy-links' routerLink='/privacy-policy'>Privacy Policy</a> 
            when using our site.
          </p>

          <p><br><span style='font-weight:500;'>ABN</span> - 42 655 920 540</p>
          <div><br></div>
          <div fxLayout='row'>
            
            <span> Mercando Pty Ltd 2022</span> 
            <div>
              <mat-icon class='copyright-icon'>copyright</mat-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</footer>