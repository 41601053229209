<mat-toolbar class='app-toolbar my-class-with-default-shadow' ngClass.lt-md='mobile-banner-card-image'>
    <div fxFlex fxShow.md fxShow.lg fxShow.xl fxHide.sm fxShow.lt-sm fxHide.xs>
        <a fxLayoutAlign='start center' routerLink='' aria-label='Home'>
            <mat-icon style='height:40px;' class='logo' svgIcon='logo-green-full' alt='Mercando Logo'></mat-icon>
        </a>
        <a mat-button (click)='openGetStartedDialog()' fxLayoutAlign='center center'><strong>Find a gardener</strong></a>
        <a mat-button routerLink='/register' fxLayoutAlign='center center' ><strong>Become a gardener</strong></a>
        <!-- <a mat-button routerLink='/referral' fxLayoutAlign='center center'><strong>NDIS participant gardeners</strong></a> -->
        
        <span fxFlex></span>
        <span style='padding-right:12px;'>
            <button *ngIf="!(user$ | async)" mat-button (click)='openAuthDialog()' style='margin-top:7px;'><strong>Sign In</strong></button>
        </span>
        <span style='padding-right:12px;'>
            <button *ngIf="user$ | async" mat-button [matMenuTriggerFor]='userMenu' style='margin-top:7px;'>
                <mat-icon color='primary' style='font-size:24px;'>account_box</mat-icon>
            </button>
            
        </span>
        <div *ngIf='cartQty' class='float'>
            <button mat-fab color='primary' routerLink='/checkout' aria-label='Shopping cart icon'>
                <mat-icon style='color:white;' [matBadge]='cartQty' matBadgeColor='warn' matBadgeSize='small' matBadgePosition="below before">shopping_cart</mat-icon>
            </button>
        </div>

        <!-- <div class='admin-icon'>
            <ngx-auth-firebaseui-avatar [links]='links' [canViewAccount]='false' [canDeleteAccount]='false'></ngx-auth-firebaseui-avatar>
        </div> -->
    </div>

    <div fxHide.md fxHide.lg fxHide.xl fxShow.sm fxShow.xs fxFlex>
        <button mat-icon-button [matMenuTriggerFor]='menu'>
            <mat-icon color='primary' style='font-size:24px;margin-top:3px;'>menu</mat-icon>
        </button>

        <mat-menu #userMenu='matMenu'>
            <a mat-menu-item routerLink='/business-admin/calendar'>
                <mat-icon>calendar_today</mat-icon>
                <span>Calendar</span>
            </a>
            <a mat-menu-item routerLink='/business-admin/settings'>
                <mat-icon>settings</mat-icon>
                <span>Settings</span>
            </a>
            <a mat-menu-item (click)='signOut()'>
                <mat-icon>logout</mat-icon>
                <span>Sign Out</span>
            </a>
          </mat-menu>
    
        <mat-menu #menu='matMenu'>
            <a mat-menu-item (click)='openGetStartedDialog()'>
                <span>Book a gardener</span> 
            </a>

            <a mat-menu-item routerLink='/register'>

                <span>Become a gardener</span> 
            </a>
            <!-- <a mat-menu-item routerLink='/ndis-program'>

                <span>NDIS participant gardeners</span> 
            </a> -->

            <a *ngIf="!(user$ | async)" style='color: #1bbc9b;' mat-menu-item (click)='openAuthDialog()'>

                <span>Sign In</span>
            </a>
        </mat-menu>
        <div *ngIf='cartQty' class='float'>
            <button mat-fab color='primary' routerLink='/checkout' aria-label='Shopping cart icon'>
                <mat-icon style='color:white;' [matBadge]='cartQty' matBadgeColor='warn' matBadgeSize='small' matBadgePosition="below before">shopping_cart</mat-icon>
            </button>
        </div>        
        <div fxFlex></div>
        <a fxLayoutAlign='start center' routerLink='' aria-label='Home'>
            <mat-icon style='height:40px;' class='logo' svgIcon='logo-green-full' alt='Mercando Logo'></mat-icon>
        </a>
        <div fxFlex></div>
        <!-- <div class='admin-icon'>
            <ngx-auth-firebaseui-avatar [links]='links' [canViewAccount]='false' [canDeleteAccount]='false'></ngx-auth-firebaseui-avatar>
        </div> -->
        <a *ngIf="(user$ | async)" fxLayoutAlign='start center' style='color: #1bbc9b;' [matMenuTriggerFor]='userMenu'>
            <mat-icon color='primary' style='font-size:24px;'>account_box</mat-icon>
        </a>
    </div>
</mat-toolbar>
<div fxLayout='column' style='height: 100%;'>
<router-outlet></router-outlet>
<div fxFlex></div>
<app-footer></app-footer>
</div>


