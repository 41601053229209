<div style='padding:15px;'>
  <h2>PRIVACY POLICY</h2>

  <h3><u>General</u></h3>
  <p>
    This website is owned and operated by Mercando (referred to hereafter as 'mercando.com.au,' 'We,' 'Us,', 'Our', or 'Our Website').
    This is the privacy policy of mercando.com.au.
    'You', 'User' and 'Your' means the person using, browsing or otherwise accessing any content or data 
    on mercando.com.au, including any subdomains of mercando.com.au, or related top-level domains, mobile sites, 
    Apps, APIs and widgets.
  </p>

  <h3><u>Collection of User Data</u></h3>
  <p>
    Mercando.com.au reserves the right to collect user data from people using the website. 
    User data is primarily used for development of mercando.com.au. 
    Mercando.com.au will take all reasonable steps to ensure that the user data, 
    which it collects, uses or discloses, is correct and is stored in a secure environment that 
    is accessed only by authorised persons.
    <br>
    Mercando.com.au may use Personal Information to: 
    set up and manage User accounts; process subscription transactions; provide customer service; 
    notify you about changes to our products or services; send account- or transaction-related communications; 
    send service-related announcements; send you newsletters, RSS feeds, text messages, and/or other 
    communications or services that you have signed up for or otherwise agreed to receive; 
    allow you to participate in interactive features of our service, when you choose to do so; 
    manage our systems; keep our mercando.com.au safe and secure; improve online operations; 
    perform research and analysis aimed at improving our products, services and technologies; 
    and display content customized to your interests or preferences.
    <br>
    We may use Your non-Personal Information (including but not limited to Personal Information 
    that has been de-identified and/or aggregated) for any reason at mercando.com.au's sole discretion.
  </p>
  <h3><u>Personal information and our website</u></h3>
  <p>
    In using mercando.com.au you agree to be bound by the terms of this Privacy Policy. 
    Mercando.com.au may review, change or update this Privacy Policy periodically 
    and reserves the right to change the Privacy Policy at any time at its discretion by posting 
    such changes on mercando.com.au. We will publish our current Privacy Policy on 
    mercando.com.au/privacy-policy-and-terms-of-service and changes will take effect immediately at the time of publication. 
    Your continued use of mercando.com.au following publication will constitute your acceptance 
    of those changes. You should review this Privacy Policy regularly and remain familiar with its terms.
  </p>
  <p>
    This Privacy Policy will not apply to other websites which are linked to or from mercando.com.au 
    and over which mercando.com.au has no control. 
    Mercando.com.au is not responsible for, the use of, 
    or the protection of information provided to, such websites.
  </p>
  <p>
    You acknowledge that no data transmission over the Internet is totally secure. 
    Accordingly, mercando.com.au does not warrant the security of any information, 
    which you transmit to it. Any information that you transmit to mercando.com.au is 
    transmitted at your own risk. You further acknowledge that Information posted on bulletin boards 
    or communicated within a social media environment (for example, Facebook, Twitter, Chat Rooms) 
    becomes public information. mercando.com.au does not guarantee the security of this type 
    of information.
  </p>
  <p>
    Personal information has the potential to be collected when you use our website through the 
    use of cookies. Cookies are pieces of data stored by your web browser on your hard drive which 
    contain information relating to your use of mercando.com.au. 
    When you access mercando.com.au, 
    cookies may record details relating to your use of mercando.com.au, 
    including what pages you view from mercando.com.au. 
    Mercando.com.au uses cookies for the detection of fraud, statistical analysis, 
    systems administration and internal management purposes only.
  </p>
  <p>
    We may use third parties to serve ads on our website. The advertisers may employ cookies and action 
    tags (also known as single pixel gifs or web beacons) to measure advertising effectiveness. Any 
    information that these third parties collect via cookies and action tags is completely anonymous.
  </p>
  <h3><u>Membership Registration and Purchases/Renewals</u></h3>
  <p>
    If you choose to sign up to mercando.com.au, then we will require several key pieces of Personal 
    Information from you. In order to access the membership areas of the site, a User is required to give 
    contact information, including name and email address. We use this information to ensure account security 
    and uniqueness and to contact the User in case a problem occurs. We may use the User's email address to 
    provide customer support, or to administer and maintain their account.
    <br> 
    In addition, a registered User may provide payment information in some circumstances (such as credit card number and expiration 
    date). This information is used for billing purposes only.
    <br>
    Note: Credit Card numbers are transmitted directly to our payment processor and never actually enter 
    mercando.com.au's systems.
  </p>
  <h3><u>Complaints and further information</u></h3>
  <p>
    If you believe your privacy has been interfered with and wish to make a complaint, 
    please contact help@mercando.com.au. 
    Your complaint will be investigated and you will be notified of the outcome. 
    You may also use the above email to request further information about the terms, 
    privacy policy or general information about mercando.com.au.
  </p>
</div>
