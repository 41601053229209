import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'easyTime'
})
export class EasyTimePipe implements PipeTransform {

  transform(value: number | string, ...args: unknown[]): unknown {
    const timeValue = value.toString();
    const hourPart = parseInt(timeValue.substring(0, timeValue.length - 2), 10);
    let minutePartInHundred = parseInt(timeValue.substring(timeValue.length - 2), 10);

    // Convert the last two digits from hundreds to actual minutes
    const minutePart = (minutePartInHundred / 100) * 60;

    // Construct the time string in HH:MM format
    let timeString = `${hourPart.toString().padStart(2, '0')}:${minutePart.toString().padStart(2, '0')}:00`;

    // Convert to a formatted time string
    const easyTimeValue = new Date(`1970-01-01T${timeString}Z`).toLocaleTimeString('en-US', {
      timeZone: 'UTC',
      hour12: true,
      hour: 'numeric',
      minute: 'numeric'
    });
    return easyTimeValue;
  }

}