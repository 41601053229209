<div *ngIf='loading' fxLayoutAlign='center center'>
    <mat-progress-spinner
        diameter='50'
        mode='indeterminate'>
    </mat-progress-spinner>
    <div style='min-height:300px;'></div>
</div>
<div *ngIf='items$ | async as items'>
    <div *ngIf='items.length == 0;else showHeaderCard;' fxLayoutAlign='center center'>
        <mat-card class='no-items-content' style='margin:20px;padding:20px;'>
            <mat-card-header>
                <h1>Purchases</h1>
            </mat-card-header>
            <mat-card-content>
                <p style='font-size:medium;'>
                    You don't have any past purchases yet.
                    Find a Microenterprise to support now in the directory.
                </p>
                <br>
                <button mat-raised-button color='primary' type='button' routerLink='/' class='action-button'>
                    View Directory
                </button>
            </mat-card-content>
        </mat-card>
    </div>
    <ng-template #showHeaderCard>
        <div fxLayoutAlign='center center'>
            <div ngClass='heading-card' [ngClass.lt-sm]="{'heading-card':false, 'heading-card-mobile':true}">
                <!-- <mat-card-header> -->
                    <h1>Past Purchases</h1>
                <!-- </mat-card-header> -->
            </div>
        </div>
    </ng-template>
    <div *ngFor='let item of items' fxLayoutAlign='center center'>
        <mat-card ngClass='purchase-card' [ngClass.lt-sm]="{'purchase-card':false, 'purchase-card-mobile':true}">
            <mat-card-subtitle style='color:black;font-size: large;'>{{item.orderCreated.toDate() | date}}</mat-card-subtitle>
            <mat-card-header fxLayoutAlign='center center' style='margin-top:20px;'>
                <mat-card-subtitle>
                    <!-- <div><span class='purchase-text'>Purchase Date: </span>{{item.orderCreated.toDate() | date}}</div>  -->
                    <div><span class='purchase-text'>Cart Total: </span>${{item.cartTotal}}</div>
                    <div><span class='purchase-text'>Tip: </span>${{item.tip}}</div>
                    <div><span class='purchase-text'>Order Reference: </span>{{item.orderId}}</div>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout='row wrap' fxLayoutAlign='space-evenly center' style='margin-bottom:20px;'>
                
                <table mat-table [dataSource]='item.cartItems' style='min-width:300px;'>

                    <ng-container matColumnDef='title' style='margin:20px;'>
                        <th mat-header-cell *matHeaderCellDef >Item:</th>
                        <td fxLayout='column' fxLayoutAlign='center center' mat-cell *matCellDef='let element'> 
                            <img style='max-width:120px; margin:5px;' [src]='element.imageUrl' onerror="this.src='../../../assets/images/missing-default.jpg'"/>
                            {{element.title}}
                            <span *ngIf="element.itemType == 'service'" style='text-align:center;'>
                                {{element.bookingTime | easyTime}} 
                                {{ element.bookingDate | easyDate}}
                            </span>
                        </td>
                    </ng-container>
                
                    <ng-container matColumnDef='qty'>
                    <th mat-header-cell *matHeaderCellDef> Qty: </th>
                    <td mat-cell *matCellDef="let element" > 
                        <span *ngIf="element.itemType == 'service'" >&nbsp;&nbsp;&nbsp;{{element.orderAmount | easyQty}}</span> 
                        <span *ngIf="element.itemType == 'product'">&nbsp;&nbsp;&nbsp;{{element.orderAmount}} </span>
                    </td>
                    </ng-container>

                    <ng-container matColumnDef='vendorLink'>
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td mat-cell *matCellDef='let element'>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <button color='primary' mat-button [routerLink]="'/' + element.vendorId" >
                                    Order Again
                            </button>
                        </td>
                    </ng-container>
                
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>             
            </mat-card-content>
        </mat-card>
    </div>    
</div>
