import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../auth.service';
import { switchMap, take } from 'rxjs/operators';

@Injectable()
export class AuthorisationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getIdToken().pipe(
      take(1),
      switchMap((token:any) => {
        if (token) {
          request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}`}
          });
        }
        return next.handle(request);
        })
      )
  }
}
