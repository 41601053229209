<mat-dialog-content>
  <div *ngIf='!createAccount && !changePassword && !emailVerificationRequired'>
    <div style='text-align:center;margin-bottom:20px;'>LOGIN TO YOUR ACCOUNT</div>

    <form [formGroup]="loginForm" (ngSubmit)="signIn()" fxLayout='column'>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" required>
      </mat-form-field>
      
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" required>
      </mat-form-field>
      <div fxLayout="column">
        <button mat-raised-button style="color: #1bbc9b;margin:8px;" type="submit">Sign In</button>
        <button mat-raised-button style="color: #DB4437;margin:8px;" (click)="signInWithGoogle()">Sign In with Google</button>
      </div>
    </form>
    <div fxLayout='row' fxLayoutAlign='center center' style='margin-top: 20px;'>
      <button mat-button (click)="displaychangePassword()">Forgot Password</button>
      <button mat-button (click)="flipAuth()">Create Account</button>
    </div>
  </div>

  <!-- <div *ngIf='createAccount && !changePassword && !emailVerificationRequired' fxLayout='column' fxLayoutAlign='center center'>

    <form [formGroup]="registerForm" (ngSubmit)="register()" fxLayout='column' fxLayoutAlign='center center'>
      <div style='text-align:center;margin-bottom:20px;'>CREATE ACCOUNT</div>
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" required>
      </mat-form-field>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-raised-button style="color: #1bbc9b;margin:8px;" type="submit">Register</button>
        <button mat-button (click)="flipAuth()">Go back</button>
      </div>
    </form>
  </div> -->

  <div *ngIf='createAccount && !changePassword && !emailVerificationRequired' fxLayout='column' fxLayoutAlign='center center'>

    <form [formGroup]="registerForm" (ngSubmit)="register()" fxLayout='column' fxLayoutAlign='center center'>
      <div style='text-align:center;margin-bottom:20px;'>CREATE ACCOUNT</div>
  
      <!-- Name Field -->
      <mat-form-field>
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" required>
      </mat-form-field>
  
      <!-- Email Field -->
      <mat-form-field>
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" type="email" required>
      </mat-form-field>
  
      <!-- Password Field -->
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input matInput formControlName="password" type="password" required>
      </mat-form-field>
  
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button color='primary' style="margin:8px;" type="submit" [disabled]="!registerForm.valid">Register</button>
        <button mat-button style="color: #1bbc9b;margin:8px;" (click)="flipAuth()">Go back</button>
      </div>
    </form>
  </div>

  <div *ngIf='changePassword && !emailVerificationRequired'>
    <form class='example-form' fxLayout='column'>
      <div class='message-text'>Send password reset email:</div>
      <mat-form-field class='example-full-width'>
        <mat-label>Email</mat-label>
        <input type='email' matInput [formControl]='emailFormControl' placeholder='Ex. pat@example.com'>
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Please enter a valid email address
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Email is <strong>required</strong>
        </mat-error>
      </mat-form-field>
      <div fxLayoutAlign='center center'>
        <button 
          mat-button 
          color='primary' 
          [disabled]="!emailFormControl.valid"
          (click)='executeChangePassword()'>
            Send
        </button>
        <button mat-button color='primary' (click)='displaychangePassword()'>Go back</button>
      </div>
    </form>
    <div *ngIf='changePasswordMessage'>
      <div class='success-text'>{{changePasswordMessage}}</div>
    </div>
  </div>

  <div *ngIf='emailVerificationRequired' fxLayout='column' fxLayoutAlign='center center'>
    <div class='message-text'>Please confirm your email</div>
    <div style='text-align: center;'>
      We sent you a verification email. Click on the link to confirm your email. 
      <br>
      If no email arrives, please check your spam/junk folder before resending.
      <br>
      <br>
      Please refresh this page once confirmed.
    </div>
    <div fxLayout='row' fxLayout.lt-md='column' style='padding:10px;padding-top:20px;'>
      <button mat-button color='primary' (click)='resendEmailVerification()'>Send new confirmation email</button>
      <button mat-button color='warn' (click)='signOut()'>Sign out</button>
    </div>
    <div 
      *ngIf='newVerificationEmailMessage' 
      flexLayoutAlign='center center' 
      class='success-text'>
        {{newVerificationEmailMessage}}
    </div>
  </div>
</mat-dialog-content>