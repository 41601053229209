// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyD_0Axz2fvhU_paIRQiG84UckTyKc-h2aM",
    authDomain: "mercando-fb.firebaseapp.com",
    projectId: "mercando-fb",
    storageBucket: "mercando-fb.appspot.com",
    messagingSenderId: "896994912873",
    appId: "1:896994912873:web:91ed2f09bca8470cbe50f8",
    measurementId: "G-K04MJ1T8KV"
  },
  contentful: {
    spaceID: 'p3d2mc20fuye',
    token: '0DiSN_bULCiY6ivAXw9rHGapsP0LiJqhNANsgrQ4HCs'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
