<div *ngIf='loading' fxLayoutAlign='center center'>
    <mat-progress-spinner
        diameter='50'
        mode='indeterminate'>
    </mat-progress-spinner>
    <div style='min-height:300px;'></div>
</div>
<div *ngIf='paymentLoading' fxLayoutAlign='center center'>
    <div fxLayout='column' fxLayoutAlign='center center'>
        <mat-progress-spinner
            diameter='50'
            mode='indeterminate'>
        </mat-progress-spinner>
        <span style='color:#1bbc9b;'>
            Processing. Please do not refresh browser.
        </span> 
    </div>
    <div style='min-height:300px;'></div>
</div>
<div [hidden]='paymentSuccess || paymentFailure || paymentLoading'>
    <div *ngIf='!loading' fxLayoutAlign='center center'>
        <mat-card
            style='max-width:690px;margin:20px;'
            ngClass='checkout-card'
            [ngClass.lt-sm]="{'checkout-card':false, 'checkout-card-mobile':true}">
                <mat-card-header >
                    <h1 [hidden]='tipSubmitted'>Checkout</h1>
                </mat-card-header>
                <mat-card-content *ngIf='checkoutItems.length > 0; else noItems'>
                    <div [hidden]='tipSubmitted'>
                        <div fxLayout='column' fxLayoutAlign='center center'>
                            <table mat-table [dataSource]='checkoutItems' class='mat-elevation-z0'>

                                <ng-container matColumnDef='title'>
                                    <th mat-header-cell *matHeaderCellDef>Item:</th>
                                    <td mat-cell *matCellDef='let element' fxLayout='column' fxLayoutAlign='center center'> 
                                        <div fxLayout='column' fxLayoutAlign='start center'>
                                            <img style='max-width:120px; margin:5px;' [src]='element.imageUrl'/>
                                            {{element.title}}
                                            <span *ngIf="element.itemType == 'service'">
                                                <!-- Hide on overnightBooking advanced service type -->
                                                <span *ngIf="element.advancedServiceType !== 'overnightBooking'">{{element.bookingTime | easyTime}} </span>

                                                {{ element.bookingDate | easyDate}}
                                            </span>
                                        </div>
                                    </td>
                                </ng-container>
                            
                                <ng-container matColumnDef='price'>
                                    <th mat-header-cell *matHeaderCellDef> <span fxHide.lt-md>Price:</span> </th>
                                    <td mat-cell *matCellDef='let element'> 
                                        <span fxHide.lt-md>{{element.price | currency}} </span>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef='qty'>
                                <th mat-header-cell *matHeaderCellDef> Qty: </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <!-- Split service and advanced service type: -->
                                    <span *ngIf="element.itemType == 'service' && !element.advancedService">{{element.orderAmount | easyQty}}</span> 
                                    <span *ngIf="element.itemType == 'service' && element.advancedServiceType == 'overnightBooking'">{{element.orderAmount | easyNightQty}}</span>
                                    
                                    <span *ngIf="element.itemType == 'product'"> {{element.orderAmount}} </span>
                                </td>
                                </ng-container>
                            
                                <ng-container matColumnDef='subTotal'>
                                <th mat-header-cell *matHeaderCellDef> Subtotal: </th>
                                <td mat-cell *matCellDef="let element"> 
                                    <!-- Split service and advanced service type: -->
                                    <span *ngIf="element.itemType == 'service'  && !element.advancedService" style='font-weight: 500;'>{{(element.orderAmount/100) * element.price | currency}} </span> 
                                    <!-- Only allow 1 times the nightly price for overnight booking advanced service type: -->
                                    <span *ngIf="element.itemType == 'service' && element.advancedServiceType == 'overnightBooking'" style='font-weight: 500;'>{{1 * element.price | currency}} </span>
                                    
                                    <span *ngIf="element.itemType == 'product'" style='font-weight: 500;'>{{(element.orderAmount) * element.price | currency}} </span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef='delete'>
                                    <th mat-header-cell *matHeaderCellDef></th>
                                    <td mat-cell *matCellDef="let element">
                                        <mat-icon
                                            *ngIf="element.itemType == 'service'"
                                            matTooltip='Delete Item' 
                                            (click)='paymentsService.clearItemFromCart(element.itemType,element.itemId,element.bookingDate,element.bookingTime); getVendorItems()' 
                                            class='delete-icon'>
                                                cancel
                                        </mat-icon>
                                        <mat-icon
                                            *ngIf="element.itemType == 'product'"
                                            matTooltip='Delete Item' 
                                            (click)='paymentsService.clearItemFromCart(element.itemType,element.itemId,undefined,undefined); getVendorItems()' 
                                            class='delete-icon'>
                                                cancel
                                        </mat-icon>
                                    </td>
                                </ng-container>
                            
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                            </table>
                            
                            <div [formGroup]='tipForm' style='padding:5px;'>
                                <div fxLayoutAlign='end end' style='font-size: large;padding:20px;margin-top:20px;'>
                                    Cart Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${{cartTotal}}
                                </div>
                                <h2>Tip Mercando Services</h2>
                                <p>
                                    <!-- Mercando does not charge Businesses a platform fee. -->
                                    We rely on the generosity of customers like you to run our extra community programs to help people with disability and others.
                                    <a routerLink='/'>Learn more.</a>
                                    <!-- We make Mercando's tools free for people with disability and others in need. 
                                    We rely on the generosity of customers like you to continue to offer this.   -->
                                    Part of this tip is also shared with the businesses in your checkout cart.

                                </p>
                                <div *ngIf='!customTip' fxLayoutAlign='center center' style='padding-top:15px;font-size:large;'>
                                    0%
                                    <mat-slider
                                        thumbLabel
                                        tickInterval='2'
                                        [displayWith]='formatLabel'
                                        step='5'
                                        formControlName='sliderValue'
                                        (change)='calculateTip()'
                                        min='0'
                                        max='30'
                                        aria-label='percentage'>
                                    </mat-slider>
                                    30%   
                                </div>
                                <div  fxLayout='column' fxLayoutAlign='center center'>
                                    <div *ngIf='customTip' fxLayoutAlign='center center'>
                                        <mat-form-field appearance='outline' fxLayoutAlign='center center'>
                                            <mat-label>Enter Amount</mat-label>
                                            <input type='number' min='0'  formControlName='tipAmount' matInput>
                                            <!-- <input type='number' min='0' [(ngModel)]='tipAmount' formControlName='customTipInput' matInput> -->
                                        </mat-form-field>
                                    </div>
                                </div>
            
                                <div *ngIf='!customTip' fxLayout='column' fxLayoutAlign='center start'>
                                    <button mat-button type='button' style='text-decoration: underline;' (click)='customTip = !customTip'>Input Custom Tip</button> 
                                </div>
                                <div *ngIf='customTip' fxLayout='column' fxLayoutAlign='center start'>
                                    <button mat-button type='button' style='text-decoration: underline;' (click)='customTip = !customTip'>Back To Tip Slider</button> 
                                </div>
                                <div fxLayoutAlign='end end' style='font-size:large;padding-right:20px;' *ngIf='!customTip'>
                                    Tip Amount: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${{tipForm.get('tipAmount')!.value}}
                                </div> 
                                <div fxLayoutAlign.lt-sm='center center'>
                                    <button 
                                    ngClass='form-button'
                                    [ngClass.lt-sm]="{'form-button':false, 'form-button-mobile':true}"
                                    color='accent' 
                                    type='submit' 
                                    [disabled]='!tipForm.valid' 
                                    (click)='continuePastTip()' 
                                    mat-raised-button>
                                        Continue
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div [hidden]='!tipSubmitted' [formGroup]='stripeForm' style='padding: 10px;'>
                        <button class='form-button' type='button' color='accent' [disabled]='!tipForm.valid' (click)='continuePastTip()' mat-raised-button>
                            <mat-icon>arrow_back</mat-icon>
                            <span>&nbsp;&nbsp;Back To Cart</span> 
                        </button>
                        <div fxLayout='row wrap' fxLayoutAlign='center center' style='padding-top:30px;'>
                            <mat-form-field appearance='fill' class='contact-field'>
                                <mat-label>First Name</mat-label>
                                <input matInput formControlName='firstName' required>
                                <mat-icon matSuffix>person</mat-icon>
                            </mat-form-field>
                            <mat-form-field appearance='fill' class='contact-field'>
                                <mat-label>Last Name</mat-label>
                                <input matInput placeholder="pat@example.com" formControlName='lastName' required>
                                <mat-icon matSuffix>person</mat-icon>
                            </mat-form-field>
                            <mat-form-field appearance='fill' class='contact-field'>
                                <mat-label>Phone number</mat-label>
                                <input matInput type='tel' formControlName='number' required>
                                <mat-icon matSuffix>phone</mat-icon>
                            </mat-form-field>
                            <mat-form-field appearance='fill' class='contact-field'>
                                <mat-label>Email</mat-label>
                                <input matInput placeholder="pat@example.com" formControlName='email' required>
                                <mat-icon matSuffix>email</mat-icon>
                            </mat-form-field>
                            <mat-form-field appearance='fill' ngClass='long-form-field' [ngClass.lt-sm]="{'long-form-field':false}">
                                <mat-label>Address</mat-label>
                                <input matInput placeholder='Ex. 4 Main St, Kew VIC 3101' formControlName='address' required>
                                <mat-icon matSuffix>home</mat-icon>
                            </mat-form-field>
                        </div>

                        <mat-card ngClass='stripe-card' [ngClass.lt-sm]="{'stripe-card':false, 'stripe-card-mobile':true}">
                            <ngx-stripe-card (change)='cardChange($event)' [options]='cardOptions' [elementsOptions]='elementsOptions'>
                            </ngx-stripe-card>
                        </mat-card>

                        <div fxLayoutAlign='center center'>
                            <mat-card style='margin-top:30px;'>
                                <div fxLayoutAlign='end end' style='font-size: large;padding:20px;'>
                                    Cart Total: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${{cartTotal}}
                                </div>   
                                <div fxLayoutAlign='end end' style='font-size:large;padding-right:20px;'>
                                    Mercando Tip: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${{tipForm.get('tipAmount')!.value}}
                                </div>
                                <div fxLayoutAlign='end end' >
                                    <div class='solid-divider'></div>
                                </div>
                                
                                <div fxLayoutAlign='end end' style='font-size:large;padding:20px;'>
                                    <b>Payment Due: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${{totalPaymentDue}}</b>
                                </div>  
                                <div fxLayoutAlign='center center'>
                                    <button 
                                        ngClass='form-button'
                                        [ngClass.lt-sm]="{'form-button':false, 'form-button-mobile':true}"
                                        mat-raised-button 
                                        style='margin-top:20px;' 
                                        color='accent' 
                                        type='submit' 
                                        (click)='pay()'
                                        [disabled]='!stripeForm.valid || !cardComplete'>
                                            Pay Now
                                    </button>
                                </div> 
                            </mat-card>
                        </div>
                        
                    </div>
                </mat-card-content>

                <ng-template #noItems>
                    <mat-card-content class='no-items-content'>
                        <p style='font-size:medium;'>
                            You don't have anything to purchase in your cart right now. 
                            Head back to our Microenterprise Directory to find something to purchase.
                        </p>
                        <br>
                        <button mat-raised-button color='primary' type='button' routerLink='/' class='action-button'>
                            View Directory
                        </button>
                    </mat-card-content>
                </ng-template>
        </mat-card>
    </div>
</div>

<div *ngIf='paymentSuccess || paymentFailure' style='max-width:400px;' fxLayout='column' fxLayoutAlign='center center'>
    <div style='min-height:75px;'></div>
    <mat-card fxLayout='column' fxLayoutAlign='center center'>
        <div style='font-size: medium;color:#1bbc9b;text-align:center;'>{{paymentSuccess}}</div>
        <div style='font-size: medium;color:red;text-align:center;'>{{paymentFailure}}</div>
        <div><br><br></div>
        <button *ngIf='paymentFailure' mat-stroked-button color='primary' (click)='paymentFailure = undefined'>Return</button>
        <button *ngIf='paymentSuccess' mat-stroked-button color='primary' routerLink='/'>Home</button>
    </mat-card>
</div>
<div style='min-height:150px;'></div>