import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AuthService } from './services/auth.service';
// import { AuthProvider, LinkMenuItem } from 'ngx-auth-firebaseui';
import { DialogAuthComponent } from './dialogs/dialog-auth/dialog-auth.component';
// import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { User } from './interfaces/user';
import { PaymentsService } from './services/payments.service';
import { DialogJoinFormComponent } from './dialogs/dialog-join-form/dialog-join-form.component';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  // providers = AuthProvider;
  // links: LinkMenuItem[];
  user$: Observable<User|null|undefined>;
  uidCheck: string | null | undefined;
  temporaryHide = true;
  cartQty: number | undefined;
  cartQtySubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;

  constructor(
    public auth: AuthService, 
    public dialog: MatDialog, 
    private router: Router,
    public afAuth: AngularFireAuth,
    matIconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    @Inject(PLATFORM_ID) private platformId: string,
    private paymentService: PaymentsService
    ) {
      this.user$ = this.auth.user$;

      // this.links = [
        // {icon: 'shopping_cart', text: 'Checkout', callback: () => this.redirectPage('checkout')},
        // {icon: 'receipt_long', text: 'Purchases', callback: () => this.redirectPage('purchases')},
      // ];
      
      const domain = (isPlatformServer(platformId)) ? 'https://mercando.com.au' : '..';
      matIconRegistry.addSvgIcon('logo-green-full', sanitizer.bypassSecurityTrustResourceUrl(domain + '/assets/icons/logo-green-full.svg'));
    }

    ngOnInit() {
      this.cartQtySubscription = this.paymentService.cart$.subscribe(stream => {
        if (stream.length == 0){
          this.cartQty = undefined;
        } else {
          this.cartQty = stream.length;
        }
      })
      if (isPlatformBrowser(this.platformId)) {
        this.userSubscription = this.user$.subscribe(res => {
          if (this.uidCheck != res?.uid) {
            this.uidCheck = res?.uid;
            if (this.uidCheck != null) {
            this.auth.isMicroenterprise(res?.uid).subscribe(res => {
              if (res) {
                if (res.isMicroenterprise == true) {
                  // this.links.unshift({icon: 'calendar_month', text: 'Business Admin', callback: () => this.redirectPage('business-admin')})
                  // this.links.unshift({icon: 'calendar_month', text: 'Business Admin', callback: () => this.redirectPage('microenterprise-admin')})
                }
              }
            })
            } else {
              // this.links = this.links.filter(obj => obj.icon !== 'calendar_month')
            }
          }
        });
        
        
      }
    }

    openAuthDialog() {
      const dialogRef = this.dialog.open(DialogAuthComponent);
    }

    signOut() {
      this.afAuth.signOut();
    }

    redirectPage(urlLocation: string) {
      this.router.navigate([urlLocation]);
    }

    ngOnDestroy(): void {
      if(this.cartQtySubscription){
        this.cartQtySubscription.unsubscribe()
      }
      if(this.userSubscription){
        this.userSubscription.unsubscribe()
      }
    }

    openGetStartedDialog(): void {
      const dialogRef = this.dialog.open(DialogJoinFormComponent, {minWidth: '100vw', height: '100vh'});
    }
}
