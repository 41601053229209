// import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
// import { NgxAuthFirebaseuiService, NgxAuthFirebaseuiComponent } from 'ngx-auth-firebaseui';

import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import firebase from 'firebase/compat/app';

@Component({
  selector: 'app-dialog-auth',
  templateUrl: './dialog-auth.component.html',
  styleUrls: ['./dialog-auth.component.scss']
})
export class DialogAuthComponent {
  loginForm: FormGroup;
  registerForm: FormGroup;
  emailFormControl: FormControl;
  createAccount = false;
  changePassword = false;
  emailVerificationRequired = false;
  changePasswordMessage = ''; 
  newVerificationEmailMessage = ''; 

  constructor(
    private dialogRef: MatDialogRef<DialogAuthComponent>,
    private fb: FormBuilder,
    private afAuth: AngularFireAuth,
    private router: Router,
    // @Inject(MAT_DIALOG_DATA) public data: any // Injecting MAT_DIALOG_DATA
  ) {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });

    this.registerForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      name: ['', [Validators.required]]
    });

    this.emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  }

  signIn() {
    const { email, password } = this.loginForm.value;
    this.afAuth.signInWithEmailAndPassword(email, password)
      .then(() => {
        // this.dialogRef.close();
        this.verifiedCheck();
      })
      .catch(error => console.error('Error signing in', error));
  }

  signInWithGoogle() {
    this.afAuth.signInWithPopup(new firebase.auth.GoogleAuthProvider())
      .then(() => {
        this.dialogRef.close();
        this.verifiedCheck();
      })
      .catch(error => console.error('Error signing in with Google', error));
  }

  register() {
    const { email, password, name } = this.registerForm.value;
    this.afAuth.createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Send email verification right after the user is registered
        userCredential.user?.sendEmailVerification()
          .then(() => {
            this.newVerificationEmailMessage = 'Verification email sent. Please check your inbox.';
          })
          .catch(error => {
            console.error('Error sending verification email:', error);
          });
  
        this.dialogRef.close();
        this.verifiedCheck();
      })
      .catch(error => console.error('Error registering', error));
  }

  verifiedCheck() {
    this.afAuth.currentUser.then(user => {
      if (user) {
        if (user.emailVerified) {
          // If the email is verified, close the dialog and navigate
          this.emailVerificationRequired = false;
          this.dialogRef.close(); // Close the dialog if email is verified
          this.router.navigate(['/']); // Redirect the user to the appropriate page
        } else {
          // If the email is NOT verified, show the verification prompt
          this.emailVerificationRequired = true;
          // this.newVerificationEmailMessage = 'Please verify your email.';
        }
      }
    }).catch(error => {
      console.error('Error checking email verification', error);
    });
  }

  displaychangePassword() {
    this.changePassword = !this.changePassword;
  }

  flipAuth() {
    this.createAccount = !this.createAccount;
  }

  executeChangePassword() {
    const email = this.emailFormControl.value;
    this.afAuth.sendPasswordResetEmail(email)
      .then(() => {
        this.changePasswordMessage = 'Password reset email sent!';
      })
      .catch(error => console.error('Error sending password reset email', error));
  }

  resendEmailVerification() {
    this.afAuth.currentUser.then(user => {
      if (user && !user.emailVerified) {
        user.sendEmailVerification()
          .then(() => {
            this.newVerificationEmailMessage = 'Verification email sent. Please check your inbox.';
          })
          .catch(error => {
            console.error('Error sending email verification', error);
            this.newVerificationEmailMessage = 'Error sending verification email. Please try again later.';
          });
      } else {
        this.newVerificationEmailMessage = 'Your email is already verified or user is not logged in.';
      }
    });
  }

  signOut() {
    this.afAuth.signOut().then(() => this.router.navigate(['/']));
  }
}