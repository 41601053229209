<div fxLayout='row' style='margin:20px;'>
    <div class='section-header'>
        <h2>Find a gardener</h2>
        <p>
            <b>1.</b> Tell us what gardening you need. 
            <br><br>
            <b>2.</b> We'll match and notify you of gardeners in your area. 
            <br><br>
            <b>3.</b> You choose the best gardener for the job. 
        </p>
    </div>
    <div fxFlex></div>
    <button mat-dialog-close mat-mini-fab color='primary' aria-label='Exit price dialog'>
        <mat-icon>close</mat-icon>
    </button>
</div>

<mat-dialog-content style='min-height:100px;'>

    <div class='section-header' *ngIf='getStartedFormResponse' style='padding-top:20px;text-align:center' fxLayoutAlign='center center'>
        <p [ngStyle]="{'color': colorExp}">{{getStartedFormResponse}}</p>
    </div>
    <div *ngIf='loadingGetStartedCall' fxLayoutAlign='center center'>
        <mat-progress-spinner color='primary' diameter='50' mode='indeterminate'></mat-progress-spinner>
    </div>
    <div [hidden]='getStartedFormResponse || loadingGetStartedCall'>

        <form [formGroup]='getStartedForm'>
        <div fxLayout='row wrap' fxLayout.lt-md='column'>
            <div fxFlex='100'>

            <div class='pb-1'>
                <mat-form-field class='full-width'>
                    <input matInput name='firstName' [formControl]="getStartedForm.controls['firstName']" placeholder='First Name'>
                </mat-form-field>
                <small *ngIf='getStartedForm?.controls?.firstName?.touched && getStartedForm.controls.firstName.errors?.required' class='form-error-msg'>First name is required</small>
            </div>

            <div class='pb-1'>
                <mat-form-field class='full-width'>
                    <input matInput name='lastName' [formControl]="getStartedForm.controls['lastName']" placeholder='Last Name'>
                </mat-form-field>
                <small *ngIf='getStartedForm?.controls?.lastName?.touched && getStartedForm.controls.lastName.errors?.required' class='form-error-msg'>Last name is required</small>
            </div>

            <div class='pb-1'>
                <mat-form-field class='full-width'>
                <input matInput name='email' [formControl]="getStartedForm.controls['email']" placeholder='Your Email'>
                </mat-form-field>
                <small *ngIf='getStartedForm.controls.email.touched && getStartedForm.controls.email.errors?.email || getStartedForm.controls.email.touched && getStartedForm.controls.email.errors?.required' class='form-error-msg'>Email is required</small>
            </div>

            <div class='pb-1'>
                <mat-form-field class='full-width'>
                <input matInput name='number' [formControl]="getStartedForm.controls['number']" placeholder='Your Number'>
                </mat-form-field>
                <small *ngIf='getStartedForm.controls.number.touched && getStartedForm.controls.number.errors?.required' class='form-error-msg'>Number is required</small>
            </div>
            </div>

            <div fxFlex='100'>
            <div class='pb-1'>
                <mat-form-field class='full-width'>
                <textarea matInput row='8' name='message' [formControl]="getStartedForm.controls['message']" placeholder='Tell us your location and what gardening you need?'></textarea>
                </mat-form-field>
            </div>
            </div>

            <!-- <div class='pb-1' fxLayout='column'>
                <label id='ndis-plan'>Do you have an NDIS plan?</label>
                <mat-radio-group
                    fxLayout='row wrap'
                    aria-labelledby='ndis-plan'
                    class='radio-group'
                    name='ndisPlanType' 
                    [formControl]="getStartedForm.controls['ndisPlanType']">
                        <mat-radio-button color='primary' class='radio-button' value='Unsure or No Plan'>Unsure or No Plan</mat-radio-button>
                        <mat-radio-button color='primary' class='radio-button' value='Self-managed'>Self-managed</mat-radio-button>
                        <mat-radio-button color='primary' class='radio-button' value='Plan-managed'>Plan-managed</mat-radio-button>
                        <mat-radio-button color='primary' class='radio-button' value='NDIA-managed'>NDIA-managed</mat-radio-button>
                </mat-radio-group>
            </div> -->
            <div class='submit-button-container' fxFlex='100'>
                <button mat-raised-button color='primary' [disabled]='!getStartedForm.valid' (click)='submitGetStartedForm(getStartedForm.value)'>
                    Submit
                </button>
            </div>
        </div>
        </form>
    </div>
</mat-dialog-content>
