import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

import { GetStarted } from '../interfaces/get-started';
import { ContactInfo } from './../interfaces/contact-info';
import { Referral, ReferralCall } from '../interfaces/referral';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  contactUrl  = 'https://mercando-api.ts.r.appspot.com/contact';
  signUpUrl   = 'https://mercando-api.ts.r.appspot.com/get-started';
  referralUrl = 'https://mercando-api.ts.r.appspot.com/referral';
  // referralUrl = 'http://127.0.0.1:8080/referral';

  constructor(private http: HttpClient) { }

  submitContact(contactFormInfo: ContactInfo): Observable <any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http
                    .post<ContactInfo>(this.contactUrl, contactFormInfo, options)
                    .pipe(catchError(this.errorHandler));
  }

  submitGetStarted(getStartedFormInfo: GetStarted): Observable <any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http
                    .post<GetStarted>(this.signUpUrl, getStartedFormInfo, options)
                    .pipe(catchError(this.errorHandler));
  }

  submitReferral(referralFormInfo: ReferralCall): Observable <any> {
    const options = { headers: new HttpHeaders().set('Content-Type', 'application/json')};

    return this.http
                    .post<GetStarted>(this.referralUrl, referralFormInfo, options)
                    .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse): any {
    console.log(error);
    return throwError(error || 'Server Error');
  }
}
