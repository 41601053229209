<div class="register-section">
    <h1 style='text-align: center;margin-top:20px;'>Choose Your Gardening Path</h1>
    <div fxLayout='row wrap' fxLayoutAlign='center center'>
      
        <!-- <a href="https://ethicaljobs.com.au/members/mercando/community-focused-gardener" target='_blank' class="text-center card-container"> -->
        <mat-card class="register-card" >
          <div fxLayout="column" fxLayoutAlign="center center"  style='min-height:300px;'>
            <mat-card-header >
                <mat-icon mat-card-avatar class="main-icon material-icons-outlined">accessibility</mat-icon>
                <mat-card-title>Independent Gardeners</mat-card-title>
                <mat-card-subtitle>View registration details</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style='text-align: center;'>
                Do you have professional gardening experience and are also helping the community or environment in some way? 
                <br><br>
                Apply to join our platform by emailing us at info@mercando.com.au! 
                <br><br>
                Include in the email your experience and why you think you might be a good fit for being an independent gardener on Mercando.
            </mat-card-content>
          </div>
          <!-- <div class="arrow-container">
            <mat-icon class="arrow-icon">arrow_forward</mat-icon>
          </div> -->
        </mat-card>
      <!-- </a> -->
      
      <!-- <a href="https://www.thefield.jobs/Job/Job/23967/none" target='_blank' class="text-center card-container"> -->
        <mat-card class="register-card" >
          <div fxLayout="column" fxLayoutAlign="center center" style='min-height:300px;'>
            <mat-card-header>
                <mat-icon mat-card-avatar class="main-icon material-icons-outlined">eco</mat-icon>
                <mat-card-title>Mercando Gardeners</mat-card-title>
                <mat-card-subtitle>View open positions</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content style='text-align: center;'>
                Mercando provides part-time employment opportunities for people with disability. 
                These positions are paid above minimum wage at the award rate for gardening and landscaping (level 3). 
                
                <br><br>
                If you are actively looking for a career in gardening, apply now by emailing us at info@mercando.com.au!

                <br><br>
                Please note, these positions are for NDIS participants and if offered a position, 
                there is a requirement to clear a police check and working with children check before starting.
            </mat-card-content>
          </div>
          <!-- <div class="arrow-container">
            <mat-icon class="arrow-icon">arrow_forward</mat-icon>
          </div> -->
        </mat-card>
      <!-- </a> -->
      
    </div>
    <div style='margin-bottom: 200px;'></div>
</div>
