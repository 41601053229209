import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EasyTimePipe } from '../pipes/easy-time.pipe';
import { EasyDatePipe } from '../pipes/easy-date.pipe';
import { EasyQtyPipe } from '../pipes/easy-qty.pipe';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { EasyNightQtyPipe } from '../pipes/easy-night-qty.pipe';
import { EasyDateWithWeekdayPipe } from '../pipes/easy-date-with-weekday.pipe';

@NgModule({
  declarations: [
    EasyTimePipe, 
    EasyDatePipe,
    EasyQtyPipe,
    EasyNightQtyPipe,
    EasyDateWithWeekdayPipe
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatListModule,
    FlexLayoutModule,
  ],
  exports: [
    EasyTimePipe,
    EasyDatePipe,
    EasyQtyPipe,
    EasyNightQtyPipe,
    EasyDateWithWeekdayPipe
  ],
})
export class SharedModule { }
