import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app'
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { from, Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators'

import { User } from '../interfaces/user';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: Observable<User|null|undefined>;

  constructor(
    private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router
  ) {
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
          // Logged in
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          // Logged out
          // this.router.navigate(['/']);
          return of(null);
        }
      })
    )
   }

  // async googleSignin() {
  //   const provider = new firebase.auth.GoogleAuthProvider();
  //   const credential = await this.afAuth.signInWithPopup(provider);
  //   return this.updateUserData(credential.user);
  // }

  isMicroenterprise(uid:any) {
    return this.afs.doc<any>(`security/${uid}`).valueChanges().pipe(take(1),catchError(this.errorHandler))
  }

  getCurrentUser() {
    return from(this.afAuth.currentUser);
  }

  getIdToken() {
    return this.afAuth.idToken
    // return firebase.auth().currentUser?.getIdToken(true)
  }

  // getCurrentUserData() {
  //   return this.afAuth.currentUser
  //   // const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
  // }

  // sendEmailVerification() {
  //   return from(this.afAuth.sendPasswordResetEmail(email)).pipe(catchError(this.errorHandler));
    
  //   // // console.log('printing auth user$: ', await this.afAuth.currentUser.emailVerified);

  //   // from(this.afAuth.currentUser).subscribe(
  //   //   user => {
  //   //     if(user?.emailVerified === false && user?.providerData[0]?.providerId === 'password') {
  //   //       console.log('cehck 1')
  //   //       return true
  //   //     } else {
  //   //       console.log('check 2')
  //   //       return false
  //   //     }
  //   //     // console.log(user?.providerData[0]?.providerId);
  //   //   }
  //   //   )
  //   // // await this.afAuth.currentUser.then(user => {
  //   // //   console.log(user?.)
  //   // // })
  //   // // this.router.navigate(['admin/verify-email']);
  // }

  resetPassword(email:string) {
    return from(this.afAuth.sendPasswordResetEmail(email)).pipe(catchError(this.errorHandler));
  }

  updateUserData(uid:string, data:User) {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${uid}`);

    // const data = { 
    //   uid: user.uid, 
    //   email: user.email
    // } 
    return userRef.set(data, { merge: true })
  }

  async signOut() {
    await this.afAuth.signOut();
    this.router.navigate(['/']);
  }

  errorHandler(error: HttpErrorResponse): any {
    console.log(error);
    return throwError(error || 'Server Error');
  }
}