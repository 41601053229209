import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-wpacs',
  templateUrl: './wpacs.component.html',
  styleUrls: ['./wpacs.component.scss']
})
export class WpacsComponent implements OnInit {

  constructor(private meta: Meta) {
    window.location.href = 'https://gumliocom.wixsite.com/wpacs';
   }

  ngOnInit(): void {
    this.meta.addTag({ name: 'robots', content: 'noindex' });
  }

}
