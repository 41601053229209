import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss']
})
export class PurchasesComponent implements OnInit {
  items$: Observable<any> | undefined;
  loading = true;
  displayedColumns: string[] = ['title', 'qty', 'vendorLink'];

  constructor(private afs:AngularFirestore, private authService:AuthService) {}

  ngOnInit(): void {
    this.authService.getCurrentUser().subscribe(res => {
      this.items$ = this.afs.collection(
        'orders',
        ref => ref.where('uid', '==', res!.uid)
      ).valueChanges().pipe(
        take(1),
        finalize(() => {
          this.loading = false;
        })
      )
    })
  }

}
