import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'easyNightQty'
})
export class EasyNightQtyPipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    let updatedValue = null;
    if (value === 900){
      updatedValue =  '1 Night ';
    } 
    // else {
    //   updatedValue = value.toString()[0] + ' Nights ';
    // }
    
    return updatedValue;
  }
}
