import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { environment } from 'src/environments/environment';
// import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { NgxStripeModule } from 'ngx-stripe';

import { MatDialogModule } from '@angular/material/dialog';

// import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatDividerModule } from '@angular/material/divider';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatBadgeModule} from '@angular/material/badge';

import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DialogAuthComponent } from './dialogs/dialog-auth/dialog-auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { CheckoutComponent } from './checkout/checkout.component';
import { DialogJoinFormComponent } from './dialogs/dialog-join-form/dialog-join-form.component';
import { CallToActionComponent } from './shared/call-to-action/call-to-action.component';
import { SharedModule } from './shared/shared.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { FooterComponent } from './footer/footer.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorisationInterceptor } from './services/interceptors/authorisation.interceptor';
import { PurchasesComponent } from './purchases/purchases.component';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { WpacsComponent } from './old-vendor-page-reroutes/wpacs/wpacs.component';
import { RegisterComponent } from './register/register.component';

@NgModule({
    declarations: [
        AppComponent,
        DialogAuthComponent,
        CheckoutComponent,
        DialogJoinFormComponent,
        CallToActionComponent,
        FooterComponent,
        PurchasesComponent,
        WpacsComponent,
        RegisterComponent,
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        FlexLayoutModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireStorageModule,
        AngularFirestoreModule,
        AngularFireAuthModule,
        // NgxAuthFirebaseUIModule.forRoot(environment.firebase, undefined, {
        //     toastMessageOnAuthSuccess: false,
        //     enableEmailVerification: true,
        //     authGuardFallbackURL: '/logged-out',
        //     authGuardLoggedInURL: '/join',
        //     guardProtectedRoutesUntilEmailIsVerified: false
        // }),
        NgxStripeModule.forRoot('pk_live_51JFZThJMDGY0fEhQGIDTQGxQjr8upyVxhbGVKIx98kGjhUgwt84gwKFeWCuZMXVKAXG0jlJbx0jmgbiz2oDZU0Ij00pjIiyG0O'
        // 'pk_test_51JFZThJMDGY0fEhQhvKGD0UeGSmQOYAU6k5dXWtnqw9LBKUTQggFto723EmJsyNgctFSVXGXSwdToOtac7djUdto00oexbZqfm'
        ),
        MatDialogModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSnackBarModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatCardModule,
        MatListModule,
        MatSliderModule,
        MatDividerModule,
        MatTableModule,
        MatBadgeModule,
        SharedModule,
        MatTooltipModule
    ],
    providers: [{
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorisationInterceptor,
            multi: true
        }],
    bootstrap: [AppComponent]
})
export class AppModule { }
